import React, { useEffect } from 'react';
import RouterNavigator from '../router/RouterNavigator';
import Header from '../components/header';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { setDinein, setTab, setUser, setUserPermissions } from '../redux/Reducer';
import MyProfile from '../data/MyProfile';
import ProvidersModel from '../data/ProvidersModel';
import PropertyModel from '../data/PropertyModel';
import { Authorize } from '../helpers/Authorize';

const Layout = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const { userRedux, fcmToken, tab } = useSelector(state => state);

    useEffect(() => {
        dispatch(setDinein(process.env.REACT_APP_DINE_IN === 'true'))
        MyProfile.get()
            .then(res => {
                dispatch(setUser(res))
            })
            .catch(err => {
                console.log(err.message)
            })
        MyProfile.getPermissions()
            .then(res => {
                dispatch(setUserPermissions(res))
                !tab && dispatch(setTab(Authorize.dinein.read(res)
                    ? 0
                    : Authorize.valet.read(res)
                        ? 1
                        : Authorize.housekeeping.read(res)
                            ? 2
                            : Authorize.amenities.read(res)
                                ? 3
                                : null))
            })
            .catch(err => {
                console.log(err.message)
            })
    }, [location])

    useEffect(() => {
        userRedux && userRedux.permissions.venues && userRedux.permissions.venues.length > 0 && userRedux.permissions.venues.map(venue =>
            ProvidersModel.getById(venue.id)
                .then(res =>
                    userRedux.permissions.venues.map(vn => ProvidersModel.update(vn.id, { ...res, deviceToken: fcmToken })
                        .then(res => () => { })
                        .catch(err => console.log({ err }))
                    )
                )
                .catch(err => {
                    console.log({ err })
                }))
    }, [userRedux])

    return (
        <>
            {location.pathname !== '/' && location.pathname !== '/forgot-pass' &&
                <Header />
            }
            <RouterNavigator />
        </>
    );
};

export default Layout;
