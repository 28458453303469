import { Input, Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { checkTranslation } from './checkTranslation';

export const liveOrderColumns = (
    searchInput,
    sortedInfo,
    filteredInfo,
    dineIn
) => [
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('no')}</span>,
            dataIndex: 'idx',
            key: 'idx',
            fixed: 'left',
            width: 100,
        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('ID')}</span>,
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
            width: 300,
            ellipsis: true,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <Input
                        ref={searchInput}
                        allowClear
                        placeholder={checkTranslation('searchByID')}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{
                            marginBottom: 8,
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                confirm()
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            {checkTranslation('search')}
                        </Button>
                    </Space>
                </div>,
            filterIcon: (filtered) =>
                <SearchOutlined
                    style={{
                        color: filtered ? '#1890ff' : undefined,
                        padding: 4
                    }}
                />,
            filteredValue: filteredInfo?.id || null,
            filterIcon: (filtered) =>
                <SearchOutlined
                    style={{
                        color: filtered ? '#1890ff' : undefined,
                        padding: 4
                    }}
                />,
            render: (id, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>
                <Link to={'/order/' + record.id} state={{ record, status: 'live' }} >{`${id} - ${record.providerId} `}</Link>
            </span>,

        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation(dineIn ? 'RESTAURANT' : 'PROVIDER')}</span>,
            dataIndex: 'provider',
            key: 'provider',
            width: 350,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <Input
                        ref={searchInput}
                        allowClear
                        placeholder={checkTranslation(dineIn ? 'searchByRest' : 'searchByProvider')}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{
                            marginBottom: 8,
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                confirm()
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            {checkTranslation('search')}
                        </Button>
                    </Space>
                </div>,
            filterIcon: (filtered) =>
                <SearchOutlined
                    style={{
                        color: filtered ? '#1890ff' : undefined,
                        padding: 4
                    }}
                />,
            filterIcon: (filtered) =>
                <SearchOutlined
                    style={{
                        color: filtered ? '#1890ff' : undefined,
                        padding: 4
                    }}
                />,
            filteredValue: filteredInfo?.provider || null,
            render: (provider, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>{provider}</span>,
        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('agent')}</span>,
            dataIndex: 'assignee',
            key: 'assignee',
            width: 350,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <Input
                        ref={searchInput}
                        allowClear
                        placeholder={checkTranslation('searchByName')}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{
                            marginBottom: 8,
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                confirm()
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            {checkTranslation('search')}
                        </Button>
                    </Space>
                </div>,
            filterIcon: (filtered) =>
                <SearchOutlined
                    style={{
                        color: filtered ? '#1890ff' : undefined,
                        padding: 4
                    }}
                />,
            filterIcon: (filtered) =>
                <SearchOutlined
                    style={{
                        color: filtered ? '#1890ff' : undefined,
                        padding: 4
                    }}
                />,
            filteredValue: filteredInfo?.assignee || null,
            render: (provider, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>{`${record.assignee.firstName} ${record.assignee.lastName}`}</span>,
        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('hotel')}</span>,
            dataIndex: 'hotel',
            key: 'hotel',
            width: 350,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <Input
                        ref={searchInput}
                        allowClear
                        placeholder={checkTranslation('searchByHotel')}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{
                            marginBottom: 8,
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                confirm()
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            {checkTranslation('search')}
                        </Button>
                    </Space>
                </div>,
            filterIcon: (filtered) =>
                <SearchOutlined
                    style={{
                        color: filtered ? '#1890ff' : undefined,
                        padding: 4
                    }}
                />,
            filteredValue: filteredInfo?.hotel || null,
            render: (hotel, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>{hotel}</span>,

        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('room')}</span>,
            dataIndex: 'room',
            key: 'room',
            width: 150,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <Input
                        ref={searchInput}
                        allowClear
                        placeholder={checkTranslation('searchByRoom')}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{
                            marginBottom: 8,
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                confirm()
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            {checkTranslation('search')}
                        </Button>
                    </Space>
                </div>,
            filterIcon: (filtered) =>
                <SearchOutlined
                    style={{
                        color: filtered ? '#1890ff' : undefined,
                        padding: 4
                    }}
                />,
            filteredValue: filteredInfo?.room || null,
            render: (room, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>{room}</span>,
        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('guestName')}</span>,
            dataIndex: 'guestName',
            key: 'guestName',
            width: 350,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <Input
                        ref={searchInput}
                        allowClear
                        placeholder={checkTranslation('searchByGuest')}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{
                            marginBottom: 8,
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                confirm()
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            {checkTranslation('search')}
                        </Button>
                    </Space>
                </div>,
            filterIcon: (filtered) =>
                <SearchOutlined
                    style={{
                        color: filtered ? '#1890ff' : undefined,
                        padding: 4
                    }}
                />,
            filteredValue: filteredInfo?.guestName || null,
            render: (guestName, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>{guestName}</span>,

        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('status')}</span>,
            dataIndex: 'status',
            key: 'status',
            width: 150,
            filters: [
                {
                    text: checkTranslation('incoming'),
                    value: 0,
                },
                {
                    text: checkTranslation('inPreparation'),
                    value: 1,
                },
                {
                    text: checkTranslation('ready'),
                    value: 2,
                },
            ],
            filterSearch: true,
            filteredValue: filteredInfo?.status || null,
            render: (status, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>{
                status === 0
                    ? checkTranslation('incoming')
                    : status === 1
                        ? checkTranslation('inPreparation')
                        : status === 2
                            ? checkTranslation('ready')
                            : status === 3
                                ? checkTranslation('pickedUp')
                                : status === 4
                                    ? checkTranslation('noShow')
                                    : checkTranslation('cancel')
            }</span>,
        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('updated')}</span>,
            dataIndex: 'updated',
            key: 'updated',
            width: 200,
            sorter: (a, b) => a.updated - b.updated,
            sortOrder: sortedInfo?.columnKey === 'updated' ? sortedInfo?.order : null,
            render: (updated, record) => {
                return <span style={{ color: '#6B7280' }}> {moment(updated).format('YYYY-MM-DD')}</span>
            },
        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('subtotal')}</span>,
            dataIndex: 'subTotal',
            key: 'subTotal',
            sorter: (a, b) => a.subTotal - b.subTotal,
            sortOrder: sortedInfo?.columnKey === 'subTotal' ? sortedInfo?.order : null,
            width: 150,
            render: (subTotal, record) => <span style={{ color: '#6B7280' }}>${subTotal}</span>
        },

    ];


export const liveOrderColumnsHouseKeeping = (
    searchInput,
    sortedInfo,
    filteredInfo,
    dineIn
) => [
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('no')}</span>,
            dataIndex: 'idx',
            key: 'idx',
            fixed: 'left',
            width: 100,
        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('room')}</span>,
            dataIndex: 'room',
            key: 'room',
            width: 150,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <Input
                        ref={searchInput}
                        allowClear
                        placeholder={checkTranslation('searchByRoom')}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{
                            marginBottom: 8,
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                confirm()
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            {checkTranslation('search')}
                        </Button>
                    </Space>
                </div>,
            filterIcon: (filtered) =>
                <SearchOutlined
                    style={{
                        color: filtered ? '#1890ff' : undefined,
                        padding: 4
                    }}
                />,
            filteredValue: filteredInfo?.room || null,
            render: (room, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>
                <Link to={'/order/' + record.id} state={{ record, status: 'live' }} >{room}</Link>
            </span>,
        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('date')}</span>,
            dataIndex: 'updated',
            key: 'updated',
            width: 200,
            sorter: (a, b) => a.updated - b.updated,
            sortOrder: sortedInfo?.columnKey === 'updated' ? sortedInfo?.order : null,
            render: (updated, record) => {
                return <span style={{ color: '#6B7280' }}> {moment(updated).format('YYYY-MM-DD')}</span>
            },
        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('preference')}</span>,
            dataIndex: 'updated',
            key: 'updated',
            width: 200,
            sorter: (a, b) => a.updated - b.updated,
            sortOrder: sortedInfo?.columnKey === 'updated' ? sortedInfo?.order : null,
            render: (updated, record) => {
                return record.meta.from && record.meta.from
                    ? <span style={{ color: '#6B7280' }}> {moment(record.meta.from).format('hh a')} - {moment(record.meta.from).format('hh a')}</span>
                    : <span>-</span>
            },
        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('agent')}</span>,
            dataIndex: 'assignee',
            key: 'assignee',
            width: 350,
            align: 'center',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <Input
                        ref={searchInput}
                        allowClear
                        placeholder={checkTranslation('searchByName')}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{
                            marginBottom: 8,
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                confirm()
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            {checkTranslation('search')}
                        </Button>
                    </Space>
                </div>,
            filterIcon: (filtered) =>
                <SearchOutlined
                    style={{
                        color: filtered ? '#1890ff' : undefined,
                        padding: 4
                    }}
                />,
            filterIcon: (filtered) =>
                <SearchOutlined
                    style={{
                        color: filtered ? '#1890ff' : undefined,
                        padding: 4
                    }}
                />,
            filteredValue: filteredInfo?.assignee || null,
            render: (provider, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>{record.assignee && record.assignee.firstName ? `${record.assignee.firstName} ${record.assignee.lastName}` : '-'}</span>,
        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('status')}</span>,
            dataIndex: 'status',
            key: 'status',
            width: 350,
            filters: [
                {
                    text: checkTranslation('incoming'),
                    value: 0,
                },
                {
                    text: checkTranslation('inPreparation'),
                    value: 1,
                },
                {
                    text: checkTranslation('ready'),
                    value: 2,
                },
            ],
            filterSearch: true,
            filteredValue: filteredInfo?.status || null,
            render: (status, record) => {
                return <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: 700, fontSize: 16, color: '#6B7280' }}>{
                        status === 0
                            ? checkTranslation('incoming')
                            : status === 1
                                ? checkTranslation('inPreparation')
                                : status === 2
                                    ? checkTranslation('ready')
                                    : status === 3
                                        ? checkTranslation('pickedUp')
                                        : status === 4
                                            ? checkTranslation('noShow')
                                            : checkTranslation('cancel')
                    }</span>
                    <span style={{ color: '#6B7280' }}><span style={{ color: '#6B7280', fontWeight: 700 }}>Request received:</span> {moment(record.created).format('HH:mm')}</span>
                    <span style={{ color: '#6B7280' }}><span style={{ color: '#6B7280', fontWeight: 700 }}>Request time:</span> {moment(record.meta.from).format('HH:mm')}-{moment(record.meta.to).format('HH:mm')}</span>
                    {record.statusHistory && record.statusHistory.length > 0 && record.statusHistory[0]?.status === 0 && <span style={{ color: '#6B7280' }}><span style={{ color: '#6B7280', fontWeight: 700 }}>Service start:</span> {moment(new Date(record.statusHistory[0].date).toString()).format('HH:mm')}</span>}
                    {record.status === 2 && <span style={{ color: '#6B7280' }}><span style={{ color: '#6B7280', fontWeight: 700 }}>Service complete:</span> {moment(record.updated).format('HH:mm')}</span>}

                </div>
            }
        },
    ];

