export const translations = {
    en: {
        no: 'No.',
        ID: 'ID',
        search: 'Search',
        reset: 'Reset',
        searchByID: 'Search by Order ID / Provider ID',
        provider: 'Provider',
        providers: 'Providers',
        PROVIDER: 'PROVIDER',
        searchByProvider: 'Search by Provider name',
        searchBySerialNum: 'Search by Serial Number',
        searchByPropertyId: 'Search by Property ID',
        searchByItemId: 'Search by Item ID',
        searchByIDRest: 'Search by Order ID / Provider ID',
        restaurant: 'Restaurant',
        restaurants: 'Restaurants',
        RESTAURANT: 'RESTAURANT',
        searchByRest: 'Search by Provider name',
        hotel: 'HOTEL',
        searchByHotel: 'Search by Hotel name',
        room: 'ROOM',
        roomNumber: 'ROOM No.',
        searchByRoom: 'Search by Room Number',
        guestName: 'GUEST NAME',
        searchByGuest: 'Search by Guest Name',
        guestEmail: 'GUEST E-MAIL',
        guestPhone: 'GUEST PHONE NUMBER',
        status: 'STATUS',
        updated: 'UPDATED',
        subtotal: 'SUBTOTAL',
        total: 'TOTAL',
        date: 'DATE',
        incoming: 'Incoming',
        inPreparation: 'In Preparation',
        inPrep: 'In Prep',
        ready: 'Ready',
        transit: 'Transit',
        pickedUp: 'Picked Up',
        noShow: 'No Show',
        cancel: 'Cancel',
        region: 'REGION',
        searchByRegion: 'Search By Region',
        open: 'OPEN',
        close: 'CLOSE',
        status: 'STATUS',
        online: 'Online',
        offline: 'Offline',
        defPrepTime: 'DEF. PREP TIME',
        min: 'min',
        activeOrders: 'ACTIVE ORDERS',
        overdueOrders: 'OVERDUE ORDERS',
        pickedUpOrders: 'PICKED UP ORDERS',
        username: 'USERNAME',
        searchByUsername: 'Search By Username',
        userEmail: 'USER EMAIL',
        searchByEmail: 'Search By Email',
        helpDesk: 'HELP DESK',
        forgotPass: 'Forgot your password',
        resetLinkInput: 'Enter the email that you used when registered to reset your password. You will receive a password reset link.',
        email: 'Email address',
        sendLink: 'Send link',
        backToLogin: 'Back to login',
        credsError: 'The combination of username / password provided was wrong. Please try again.',
        mustResetPass: 'You have to reset your password',
        signInTxt: 'Sign in to your account',
        rememberMe: 'Remember me',
        signIn: 'Sign in',
        createNewPass: 'Create new password',
        newPass: 'New Password',
        repeatPass: 'Repeat the password',
        submit: 'Submit',
        clearAllFilters: 'Clear all filters',
        openRequests: 'Open Requests',
        orderDetails: 'Order Details',
        allergy: 'ALLERGY',
        dressing: 'Dressing',
        notes: 'Item Notes',
        orderNotes: 'Order Notes',
        pickUpTime: 'Pick Up Time:',
        totalPrepTime: 'Total Prep Time:',
        orderSummary: 'Order Summary',
        order: 'Order',
        archive: 'Archive',
        items: 'ITEMS',
        item: 'Item',
        workRequestId: 'Work Request ID',
        serialNumber: 'Serial Number',
        persons: 'persons',
        helpDesk: 'HelpDesk',
        searchByHelpDesk: 'Search By HelpDesk',
        passNotIdenticalError: 'These two passwords are not identical! Please type the same password in both fields',
        changePassSuccess: 'You have changed the password successfully!',
        orders: 'Orders',
        signOutWarning: 'WARNING!\nYou will be signed out in 2 minutes!',
        users: 'Users',
        logOut: 'Log Out',
        profile: 'Profile',
        discount: 'DISCOUNT',
        itemPrice: 'Item Price: ',
        currency: '$',
        valet: 'Valet',
        housekeeping: 'Housekeeping',
        amenities: 'Amenities',
        all: 'All',
        agent: 'AGENT',
        searchByName: 'Search by name',
        inProgress: 'In Progress',
        serviced: 'Serviced',
        requests: 'Requests',
        preference: 'PREFERENCE',
        dispatcher: 'DISPATCHER',
        pickupTray: 'Pickup Tray',
        created: 'Created',
        requestReceived: 'Request Received',
        requestTime: 'Request Time',
        serviceStart: 'Service Start',
        serviceComplete: 'Service Complete',
        closeTime: 'Close Time',
        synergyAudits: 'Synergy Audits',
    }
}