import React from 'react';
import {NavLink} from 'react-router-dom';

const NavigatorLink = (props) => {

    return (
        <NavLink className="nav-link" to={props.navigateTo}>{props.title}</NavLink>
    );
};

export default NavigatorLink;
