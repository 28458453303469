import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    typeRedux: null,
    loadingRedux: false,
    userPermissions: null,
    userRedux: null,
    dineIn: {},
    fcmToken: '',
    tab: null,
    allMine: 0,
    filteredInfo: null,
    sortedInfo: null,
    pageNumber: 1,
    pageSize: 10,
    assignee: null,
};


export const stateSlice = createSlice({
    name: 'myApp',
    initialState,
    reducers: {
        setType: (state, { payload }) => {
            state.typeRedux = payload
        },
        setLoading: (state, { payload }) => {
            state.loadingRedux = payload
        },
        setUserPermissions: (state, { payload }) => {
            state.userPermissions = payload
        },
        setTab: (state, { payload }) => {
            state.tab = payload
        },
        setUser: (state, { payload }) => {
            state.userRedux = payload
        },
        setDinein: (state, { payload }) => {
            state.dineIn = payload
        },
        setFcmToken: (state, { payload }) => {
            state.fcmToken = payload
        },
        setAllMine: (state, { payload }) => {
            state.allMine = payload
        },
        setFilteredInfo: (state, { payload }) => {
            state.filteredInfo = payload
        },
        setSortedInfo: (state, { payload }) => {
            state.sortedInfo = payload
        },
        setPageNumber: (state, { payload }) => {
            state.pageNumber = payload
        },
        setPageSize: (state, { payload }) => {
            state.pageSize = payload
        },
        setAssignee: (state, { payload }) => {
            state.assignee = payload
        },
    },
})

export const {
    setType,
    setLoading,
    setUserPermissions,
    setTab,
    setUser,
    setDinein,
    setFcmToken,
    setAllMine,
    setFilteredInfo,
    setSortedInfo,
    setPageNumber,
    setPageSize,
    setAssignee,
} = stateSlice.actions

export default stateSlice.reducer

