import { message } from 'antd';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { checkTranslation } from '../../helpers/checkTranslation';

const NewPass = () => {

    const [pass, setPass] = useState(null)
    const [repeat, setRepeat] = useState(null)

    const submit = () => {
        if (pass.length < 6) {
            message.error('Password must have more than 6 characters!')
        } else {
            if (pass !== repeat) {
                message.error('These two passwords are not identical! Please type the same password in both fields')
            } else {
                message.success('Success')
            }
        }
    };

    return (
        <div id="page" className="site site--account-credentials">
            <section className="center-block center-block--alone account-credentials">
                <div className="account-credentials--block">
                    <div className="logo">
                        <img src="./assets/img/hcn-logo.png" alt="site logo" className="site-logo" />
                    </div>
                    <h1 className="big-title">{checkTranslation('createNewPass')}</h1>
                    <div action="" className="account-credentials--form">
                        <label htmlFor="email">{checkTranslation('newPass')}
                            <input type="password" min={6} className="default default--full" name="email"
                                placeholder="" defaultValue={null} onChange={({ target: { value } }) => {
                                    setPass(value)
                                }} />
                        </label>
                        <label htmlFor="email">{checkTranslation('repeatPass')}
                            <input type="password" min={6} className="default default--full" name="email"
                                placeholder="" defaultValue={null} onChange={({ target: { value } }) => {
                                    setRepeat(value)
                                }} />
                        </label>

                        <button className="btn btn--secondary btn--full" style={{ marginTop: '5vh' }} onClick={submit}>{checkTranslation('submit')}</button>

                    </div>

                    <NavLink to="/" className="link top-spacing back">{checkTranslation('backToLogin')}</NavLink>
                </div>
            </section>

        </div>
    );
};

export default NewPass;