import React, {Component} from 'react';
import './loader.css';

const Loader = ({ loading }) => {
    return (
        <div id="loader" className={loading ? 'loader-container' : 'loader-container loader-hidden'}>
            <div className="spinner spinner3"></div>
        </div>
    );
};

export default Loader;