import { FileDoneOutlined } from '@ant-design/icons';
import { Button, Card, Col, message, Row, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { RestData, ProviderColumns } from '../helpers/TableProviders'
import ProvidersModel from '../data/ProvidersModel'
import { rest } from 'lodash';
import moment from 'moment';
import { checkTranslation } from '../helpers/checkTranslation';
import { useSelector } from 'react-redux';

const Providers = () => {

    const [providers, setProviders] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [searchedColumn, setSearchedColumn] = useState(null);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [selectedValue, setSelectedValue] = useState('Providers');

    const searchInput = useRef(null);
    const { dineIn } = useSelector(state => state);


    useEffect(() => {
        ProvidersModel.getByType(1)
            .then(res => {
                res && setProviders(
                    res.map((rest, idx) => ({
                        ...rest,
                        idx: idx + 1,
                        deliveryTime: rest.deliveryTime,
                        open: rest.schedule.openedAt,
                        close: rest.schedule.closedAt,
                        region: rest.timezone,
                        status: rest.status,
                        tablet: rest.active,
                        activeOrders: rest.orderStats.inPreparation,
                        overdueOrders: rest.orderStats.overdue,
                        pickedUpOrders: rest.orderStats.pickedUp,
                    })))
            })
            .catch(err => message.error(err.message))
    }, [])

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const handleResetAll = (clearFilters) => {
        setFilteredInfo({});
        setSortedInfo({});
    };
    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };


    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
        if (event.target.value === 'Providers') {
            ProvidersModel.getByType(1)
                .then(res => {
                    res && setProviders(
                        res.map((rest, idx) => ({
                            ...rest,
                            idx: idx + 1,
                            deliveryTime: rest.deliveryTime,
                            open: rest.schedule.openedAt,
                            close: rest.schedule.closedAt,
                            region: rest.timezone,
                            status: rest.status,
                            tablet: rest.active,
                            activeOrders: rest.orderStats.inPreparation,
                            overdueOrders: rest.orderStats.overdue,
                            pickedUpOrders: rest.orderStats.pickedUp,
                        })))
                })
                .catch(err => message.error(err.message))
        } else {
            ProvidersModel.getByType(2)
                .then(res => {
                    res && setProviders(
                        res.map((rest, idx) => ({
                            ...rest,
                            idx: idx + 1,
                            deliveryTime: rest.deliveryTime,
                            open: rest.schedule.openedAt,
                            close: rest.schedule.closedAt,
                            region: rest.timezone,
                            status: rest.status,
                            tablet: rest.active,
                            activeOrders: rest.orderStats.inPreparation,
                            overdueOrders: rest.orderStats.overdue,
                            pickedUpOrders: rest.orderStats.pickedUp,
                        })))
                })
                .catch(err => message.error(err.message))
        }
    };

    return <div style={{ padding: 24 }}>
        <h1 style={{ textAlign: 'start' }}>
            <select id="dropdown" value={selectedValue} onChange={handleSelectChange}>
                <option value="Providers">Providers</option>
                <option value="Restaurants">Restaurants</option>
            </select>
        </h1>
        <br />
        <br />
        <br />

        <div style={{ float: 'right' }}>
            <Button
                type='primary'
                onClick={handleResetAll}
            >
                {checkTranslation('clearAllFilters')}
            </Button>
        </div>
        <br />
        <br />
        <Table
            bordered
            dataSource={providers}
            columns={ProviderColumns(handleReset, handleSearch, searchInput, filteredInfo, sortedInfo, dineIn, selectedValue)}
            onChange={handleChange}
            scroll={{
                x: 800,
            }}
            pagination={false}
        />

    </div>
}

export default Providers
