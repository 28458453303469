import { Radio } from 'antd';
import React from 'react';

const Radios = ({ setTable, table }) => {

    return <Radio.Group onChange={(e) => {
        setTable(e.target.value)
    }}>
        <Radio.Button style={table ? { backgroundColor: '#1793C2', color: '#fff', } : {}} value={true}>Report</Radio.Button>
        <Radio.Button style={!table ? { backgroundColor: '#1793C2', color: '#fff', } : {}} value={false}>Dispatch</Radio.Button>
    </Radio.Group>
}

export default Radios