
const Config = {
    LOCAL_DATA_KEYS: {
        ACCESS_TOKEN: 'hcn-admin-access-token',
        JWT_ACCESS_TOKEN: 'hcn-admin-jwt-access-token',
    },
    ROLES: {
        ADMIN: 'admin',
    },
    REDUCER_TYPES: {
        SET_TYPE: 'SET_TYPE',
        SET_LOADING: 'SET_LOADING',
        SET_USER: 'SET_USER',
    },

    getAccessTokenUsername: () => {
        return 'helpdesk';
    },
    getAccessTokenPassword: () => {
        return 'NBfdT71DHRgdRP9sVoBJtc7WIruGeRBOCkDE9pM3QzTubRRgpnp5g9OHgu7LkajD';
    },
    getBaseURL: () => {
        // eslint-disable-next-line no-undef
        return process.env.REACT_APP_API_PATH_DINE_IN;
    },
    getAuthBasePath: () => {
        // eslint-disable-next-line no-undef
        return process.env.REACT_APP_API_PATH_AUTH_BACKEND;
    },
    getS3BucketPrefix: () => {
        return process.env.REACT_APP_S3_BUCKET_PREFIX;
    },
    getHotelPath: () => {
        return process.env.REACT_APP_API_PATH_NAVIGATOR_BACKEND;
    },
    getVersion: () => {
        return hash;
    },
    getAdminPortalUrl: () => {
        return process.env.REACT_APP_ADMIN_PORTAL_URL;
    },
    gglAPIKey: 'AIzaSyCGVAN1TGankqo3unLfF_2AovemGWDK2OA',
    editorAPIKey: 'npntgnd8ki5n14etsdl7blwcdp20pxw53fbe66bxp33rowuo',
};

export default Config;
