import Services from './Services';
import Config from '../config';
import {Tag} from "antd";

const SYNERGY_WR_STATUS = {
    0: "Not Dispatched",
    1: "Dispatched",
    2: "Accepted",
    3: "Rejected",
    4: "Failed",
    5: "Completed",
    6: "Escalated",
    8: "Closed"
}

const SYNERGY_WR_STATUS_TAG = {
    0: "default",
    1: "processing",
    2: "processing",
    3: "warning",
    4: "error",
    5: "success",
    6: "warning",
    8: "success"
}



const SynergyAuditsModel = {
    getStatusMap: () => {
        return SYNERGY_WR_STATUS;
    },
    getStatusByNumber: (num) => {
        return SYNERGY_WR_STATUS[num] || "Unknown Status"
    },
    getStatusTagByNumber: (num) => {
        return SYNERGY_WR_STATUS_TAG[num] || "default"
    },
    getAll: (page, size, hotel, room, item, serialNum) => {
        return Services.getApi(Config.getBaseURL() +
            `/synergy/audits?pageNumber=${page}&pageSize=${size}` +
            `${hotel ? '&propertyId=' + hotel : ''}` +
            `${serialNum ? '&serialNum=' + serialNum : ''}` +
            `${room ? '&room=' + `${room}` : ''}` +
            `${item ? '&itemId=' + item : ''}`
        );
    },
    getBySerialNumAndItemId: async (serialNum, itemId) => {
        const res = await Services.getApi(Config.getBaseURL() +
            `/synergy/audits?` +
            `${serialNum ? '&serialNum=' + `${serialNum}` : ''}` +
            `${itemId ? '&itemId=' + itemId : ''}`
        );
        return res?.data?.at(0);
    },
};

export default SynergyAuditsModel;
