import { Avatar, Button, Col, Row, Select } from 'antd';
import React from 'react';
import DatagridCard from './DataGridcard';
import Radios from './Radios';
import { CheckPermission } from '../helpers/CheckreadPermission';
import { useDispatch, useSelector } from 'react-redux';
import { setAllMine } from '../redux/Reducer';

const { Option } = Select;

const DataGrid = ({
    incoming,
    inPrep,
    serviced,
    table,
    setTable,
    setVisible,
    setOrderID,
    setOrderStatus,
    statistics,
    tab,
    setRefresh,
    refresh,
    setAssignee
}) => {
    const { userPermissions, userRedux, allMine } = useSelector(state => state);

    const dispatch = useDispatch();

    return <div>
        <div style={{ textAlign: 'left' }}>
            <Radios table={table} setTable={setTable} />
        </div>
        <Row style={{ marginTop: 40 }}>
            <Col span={8} style={{ padding: 12, borderRight: '1px solid #000', height: '100vh' }}>
                <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ fontSize: 20, color: '#000' }}>
                        Incoming
                        <Avatar style={{
                            backgroundColor: '#DC9100'
                        }}>
                            {statistics.inTransit}
                        </Avatar>
                    </span>
                    {CheckPermission(tab, userPermissions) &&
                        <Select
                            bordered={false}
                            value={allMine}
                            style={{ width: '30%' }}
                            onChange={value => {
                                dispatch(setAllMine(value))
                                setRefresh(!refresh)
                                value === 1 ? dispatch(setAssignee(userRedux.id)) : dispatch(setAssignee(null))
                            }}
                        >
                            <Option value={0}>All</Option>
                            <Option value={1}>Mine</Option>
                        </Select>}
                </Row>
                {incoming.map(x => <DatagridCard item={x} incoming={false} setOrderStatus={setOrderStatus} setOrderID={setOrderID} setVisible={setVisible} isServiced={false} setRefresh={setRefresh} refresh={refresh} tab={tab} />)}
            </Col>
            <Col span={8} style={{ padding: 12, borderRight: '1px solid #000', }}>
                <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ fontSize: 20, color: '#000' }}>
                        In Progress
                        <Avatar style={{
                            backgroundColor: '#F35D3C'
                        }}>
                            {statistics.inPreparation}
                        </Avatar>
                    </span>
                </Row>
                {inPrep.map(x => <DatagridCard item={x} setOrderStatus={setOrderStatus} incoming={true} setOrderID={setOrderID} setVisible={setVisible} isServiced={false} setRefresh={setRefresh} refresh={refresh} tab={tab} />)}
            </Col>
            <Col span={8} style={{ padding: 12 }}>
                <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ fontSize: 20, color: '#000' }}>
                        Serviced
                        <Avatar style={{
                            backgroundColor: '#239421'
                        }}>
                            {statistics.ready}
                        </Avatar>
                    </span>
                </Row>
                {serviced.map(x => <DatagridCard item={x} setOrderStatus={setOrderStatus} setOrderID={setOrderID} setVisible={setVisible} incoming={true} isServiced={true} setRefresh={setRefresh} refresh={refresh} tab={tab} />)}
            </Col>
        </Row>
    </div>
}

export default DataGrid