import { Input, Space, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { checkTranslation } from './checkTranslation';

export const ProviderColumns = (handleReset, handleSearch, searchInput, filteredInfo, sortedInfo, dineIn, selectedValue) => [
    {
        title: <span style={{ color: '#6B7280' }}>{checkTranslation('no')}</span>,
        dataIndex: 'idx',
        key: 'idx',
        fixed: 'left',
        width: 70,
    },
    {
        title: <span style={{ color: '#6B7280' }}>{selectedValue}</span>,
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        width: 200,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={checkTranslation(dineIn ? 'searchByRest' : 'searchByProvider')}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, 'name')}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, 'name')}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {checkTranslation('search')}
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            clearFilters && handleSearch(selectedKeys, confirm, 'name')
                        }
                        }
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {checkTranslation('reset')}
                    </Button>
                </Space>
            </div>,
        filterIcon: (filtered) =>
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />,
        filteredValue: filteredInfo.name || null,
        onFilter: (value, record) =>
            record.name?.toString().toLowerCase().includes(value.toLowerCase()),
        render: (name, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>{name}</span>,
    },
    {
        title: <span style={{ color: '#6B7280' }}>{checkTranslation('region')}</span>,
        dataIndex: 'region',
        key: 'region',
        width: 150,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={checkTranslation('searchByRegion')}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, 'region')}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, 'region')}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {checkTranslation('search')}
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            clearFilters && handleSearch(selectedKeys, confirm, 'region')
                        }
                        }
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {checkTranslation('reset')}
                    </Button>
                </Space>
            </div>,
        filterIcon: (filtered) =>
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />,
        filteredValue: filteredInfo.region || null,
        onFilter: (value, record) =>
            record.region?.toString().toLowerCase().includes(value.toLowerCase()),
        render: (region, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>{region}</span>,

    },
    {
        title: <span style={{ color: '#6B7280' }}>{checkTranslation('open')}</span>,
        dataIndex: 'open',
        key: 'open',
        width: 200,
        sortOrder: sortedInfo.columnKey === 'open' ? sortedInfo.order : null,
        sorter: (a, b) => moment(a.open) - moment(b.open),
        render: (open, record) => <span style={{ color: '#6B7280' }}>{open ? moment.utc(open).format('YYYY-MM-DD - HH:mm') : ''}</span>
    },
    {
        title: <span style={{ color: '#6B7280' }}>{checkTranslation('close')}</span>,
        dataIndex: 'close',
        key: 'close',
        width: 200,
        sortOrder: sortedInfo.columnKey === 'close' ? sortedInfo.order : null,
        sorter: (a, b) => moment(a.close) - moment(b.close),
        render: (close, record) => <span style={{ color: '#6B7280' }}>{close ? moment.utc(close).format('YYYY-MM-DD - HH:mm') : ''}</span>
    },
    {
        title: <span style={{ color: '#6B7280' }}>{checkTranslation('status')}</span>,
        dataIndex: 'status',
        key: 'status',
        width: 100,
        filters: [
            {
                text: checkTranslation('online'),
                value: 1,
            },
            {
                text: checkTranslation('offline'),
                value: 0,
            },
        ],
        filterSearch: true,
        filteredValue: filteredInfo.status || null,
        onFilter: (value, record) => record.status === value,
        render: (status, record) => <span style={{ marginLeft: 2, color: status === 0 ? 'red' : 'green' }}>{status === 0 ? 'Offline' : 'Online'}</span>,

    },
    {
        title: <span style={{ color: '#6B7280' }}>{checkTranslation('defPrepTime')}</span>,
        dataIndex: 'deliveryTime',
        key: 'deliveryTime',
        width: 100,
        sortOrder: sortedInfo.columnKey === 'deliveryTime' ? sortedInfo.order : null,
        sorter: (a, b) => a.deliveryTime - b.deliveryTime,
        render: (deliveryTime, record) => <span style={{ color: '#6B7280' }}>{deliveryTime} min</span>
    },
    {
        title: <span style={{ color: '#6B7280' }}>{checkTranslation('activeOrders')}</span>,
        dataIndex: 'activeOrders',
        key: 'activeOrders',
        width: 100,
        sortOrder: sortedInfo.columnKey === 'activeOrders' ? sortedInfo.order : null,
        sorter: (a, b) => a.activeOrders - b.activeOrders,
        render: (activeOrders, record) => <span style={{ color: '#6B7280' }}>{activeOrders}</span>
    },
    {
        title: <span style={{ color: '#6B7280' }}>{checkTranslation('overdueOrders')}</span>,
        dataIndex: 'overdueOrders',
        key: 'overdueOrders',
        width: 100,
        sortOrder: sortedInfo.columnKey === 'overdueOrders' ? sortedInfo.order : null,
        sorter: (a, b) => a.overdueOrders - b.overdueOrders,
        render: (overdueOrders, record) => <span style={{ color: '#6B7280' }}>{overdueOrders} </span>
    },
    {
        title: <span style={{ color: '#6B7280' }}>{checkTranslation('pickedUpOrders')}</span>,
        dataIndex: 'pickedUpOrders',
        key: 'pickedUpOrders',
        width: 100,
        sortOrder: sortedInfo.columnKey === 'pickedUpOrders' ? sortedInfo.order : null,
        sorter: (a, b) => a.pickedUpOrders - b.pickedUpOrders,
        render: (pickedUpOrders, record) => <span style={{ color: '#6B7280' }}>{pickedUpOrders}</span>
    },
]
