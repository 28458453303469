import { FileDoneOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, message, Row, Spin, Table, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OrdersModel from '../data/OrdersModel';
import { checkTranslation } from '../helpers/checkTranslation';
import { liveOrderColumns } from '../helpers/TableOrderHistory'
import { Authorize } from '../helpers/Authorize';
import { liveOrderColumnsHouseKeeping } from '../helpers/TableLiveOrders';
import { setAssignee, setFilteredInfo, setPageNumber, setPageSize, setSortedInfo, setTab } from '../redux/Reducer';
import moment from 'moment';


const LiveOrders = () => {

    const [ordersHistory, setOrdersHistory] = useState(null);
    const [totalPages, setTotalPages] = useState(null);
    const [reloadAPI, setReloadAPI] = useState(false);

    const searchInput = useRef(null);
    const {
        dineIn,
        userPermissions,
        tab,
        filteredInfo,
        sortedInfo,
        pageNumber,
        pageSize,
    } = useSelector(state => state);

    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            setReloadAPI(!reloadAPI)
        }, 30000);
    }, [ordersHistory])

    useEffect(() => {
        OrdersModel.getAllHistory(
            pageNumber,
            pageSize,
            filteredInfo?.id?.[0],
            filteredInfo?.provider?.[0],
            filteredInfo?.hotel?.[0],
            filteredInfo?.room?.[0],
            filteredInfo?.guestName?.[0],
            filteredInfo?.status,
            sortedInfo,
            tab,
        )
            .then(res => {
                res && res.totalPages && setTotalPages(res.total)
                res && res.data?.length > 0 ?
                    setOrdersHistory(
                        res.data.map((x, idx) => ({
                            ...x,
                            idx: idx + 1,
                            provider: x.provider.name,
                            hotel: x.hotel.name,
                            guestName: `${x.firstName} ${x.lastName}`,
                            subTotal: x.paymentAnalysis.subTotal
                        })))
                    : setOrdersHistory([])
            })
            .catch(err => {
                console.log(err.message)
            });
    }, [pageNumber, pageSize, filteredInfo, sortedInfo, tab, reloadAPI])

    const handleChange = (pagination, filters, sorter, extra) => {
        dispatch(setFilteredInfo(filters))
        dispatch(setSortedInfo(sorter))
    };

    const items = [
        Authorize.dinein.read(userPermissions) && {
            label: <span style={{ fontSize: 17 }}>
                {checkTranslation('restaurants')}
            </span>,
            key: 0,
            children: <div>
                <div style={{ float: 'right' }}>
                    <Button
                        type='primary'
                        onClick={() => {
                            dispatch(setFilteredInfo(null))
                            dispatch(setSortedInfo(null))
                            dispatch(setPageNumber(1))
                            dispatch(setPageSize(10))
                            setTimeout(() => { window.location.reload() }, 200)
                        }}
                    >
                        {checkTranslation('clearAllFilters')}
                    </Button>
                </div>
                <br />
                <br />
                <Table
                    bordered
                    dataSource={ordersHistory}
                    columns={
                        liveOrderColumns(
                            searchInput,
                            sortedInfo,
                            filteredInfo,
                            dineIn,
                        )}
                    scroll={{
                        x: 800,
                    }}
                    pagination={{
                        total: totalPages,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        defaultCurrent: pageNumber,
                        defaultPageSize: pageSize,
                        onChange: (num, size) => {
                            dispatch(setPageSize(size))
                            dispatch(setPageNumber(num))
                        },
                    }}
                    onChange={handleChange}
                />
            </div>
        },
        Authorize.valet.read(userPermissions) && {
            label: <span style={{ fontSize: 17 }}>
                {checkTranslation('valet')}
            </span>,
            key: 1,
            children: <div>
                <div style={{ float: 'right' }}>
                    <Button
                        type='primary'
                        onClick={() => {
                            dispatch(setFilteredInfo(null))
                            dispatch(setSortedInfo(null))
                            dispatch(setPageNumber(1))
                            dispatch(setPageSize(10))
                            setTimeout(() => { window.location.reload() }, 200)
                        }}
                    >
                        {checkTranslation('clearAllFilters')}
                    </Button>
                </div>
                <br />
                <br />
                <Table
                    bordered
                    dataSource={ordersHistory}
                    columns={
                        liveOrderColumns(
                            searchInput,
                            sortedInfo,
                            filteredInfo,
                            dineIn,
                        )}
                    scroll={{
                        x: 800,
                    }}
                    pagination={{
                        total: totalPages,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        defaultCurrent: pageNumber,
                        defaultPageSize: pageSize,
                        onChange: (num, size) => {
                            dispatch(setPageSize(size))
                            dispatch(setPageNumber(num))
                        },
                    }}
                    onChange={handleChange}
                />
            </div>
        },
        Authorize.housekeeping.read(userPermissions) && {
            label: <span style={{ fontSize: 17 }}>
                {checkTranslation('housekeeping')}
            </span>,
            key: 2,
            children: <div>
                <div style={{ float: 'right' }}>
                    <Button
                        type='primary'
                        onClick={() => {
                            dispatch(setFilteredInfo(null))
                            dispatch(setSortedInfo(null))
                            dispatch(setPageNumber(1))
                            dispatch(setPageSize(10))
                            setTimeout(() => { window.location.reload() }, 200)
                        }}
                    >
                        {checkTranslation('clearAllFilters')}
                    </Button>
                </div>
                <br />
                <br />
                <Table
                    bordered
                    dataSource={ordersHistory}
                    columns={
                        liveOrderColumnsHouseKeeping(
                            searchInput,
                            sortedInfo,
                            filteredInfo,
                            dineIn,
                        )}
                    scroll={{
                        x: 800,
                    }}
                    pagination={{
                        total: totalPages,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        defaultCurrent: pageNumber,
                        defaultPageSize: pageSize,
                        onChange: (num, size) => {
                            dispatch(setPageSize(size))
                            dispatch(setPageNumber(num))
                        },
                    }}
                    onChange={handleChange}
                />
            </div>
        },
        Authorize.amenities.read(userPermissions) && {
            label: <span style={{ fontSize: 17 }}>
                {checkTranslation('amenities')}
            </span>,
            key: 3,
            children: <div>
                <div style={{ float: 'right' }}>
                    <Button
                        type='primary'
                        onClick={() => {
                            dispatch(setFilteredInfo(null))
                            dispatch(setSortedInfo(null))
                            dispatch(setPageNumber(1))
                            dispatch(setPageSize(10))
                            setTimeout(() => { window.location.reload() }, 200)
                        }}
                    >
                        {checkTranslation('clearAllFilters')}
                    </Button>
                </div>
                <br />
                <br />
                <Table
                    bordered
                    dataSource={ordersHistory}
                    columns={
                        liveOrderColumns(
                            searchInput,
                            sortedInfo,
                            filteredInfo,
                            dineIn,
                        )}
                    scroll={{
                        x: 800,
                    }}
                    pagination={{
                        total: totalPages,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        defaultCurrent: pageNumber,
                        defaultPageSize: pageSize,
                        onChange: (num, size) => {
                            dispatch(setPageSize(size))
                            dispatch(setPageNumber(num))
                        },
                    }}
                    onChange={handleChange}
                />
            </div>
        },
        Authorize.tray_pickup.read(userPermissions) && {
            label: <span style={{ fontSize: 17 }}>
                {checkTranslation('pickupTray')}
            </span>,
            key: 4,
            children: <div>
                <div style={{ float: 'right' }}>
                    <Button
                        type='primary'
                        onClick={() => {
                            dispatch(setFilteredInfo(null))
                            dispatch(setSortedInfo(null))
                            dispatch(setPageNumber(1))
                            dispatch(setPageSize(10))
                            setTimeout(() => { window.location.reload() }, 200)
                        }}
                    >
                        {checkTranslation('clearAllFilters')}
                    </Button>
                </div>
                <br />
                <br />
                <Table
                    bordered
                    dataSource={ordersHistory}
                    columns={
                        liveOrderColumns(
                            searchInput,
                            sortedInfo,
                            filteredInfo,
                            dineIn,
                        )}
                    scroll={{
                        x: 800,
                    }}
                    pagination={{
                        total: totalPages,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        defaultCurrent: pageNumber,
                        defaultPageSize: pageSize,
                        onChange: (num, size) => {
                            dispatch(setPageSize(size))
                            dispatch(setPageNumber(num))
                        },
                    }}
                    onChange={handleChange}
                />
            </div>
        },

    ];

    return <div style={{ padding: 24 }}>
        <h1 style={{ textAlign: 'start' }}>{checkTranslation('archive')}</h1>
        <Tabs
            activeKey={tab}
            items={items}
            onChange={value => {
                dispatch(setTab(value))
                dispatch(setPageNumber(1))
                dispatch(setPageSize(10))
            }}

        />
    </div>
}

export default LiveOrders