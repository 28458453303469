import { Authorize } from "./Authorize"

export const CheckPermission = (tab, userPermissions) => {
    return tab === 0 && Authorize.dinein.readAll(userPermissions)
        ? true
        : tab === 1 && Authorize.valet.readAll(userPermissions)
            ? true
            : tab === 2 && Authorize.housekeeping.readAll(userPermissions)
                ? true
                : tab === 3 && Authorize.amenities.readAll(userPermissions)
                    ? true
                    : false
}