import Services from './Services';
import Config from '../config';

const UsersModel = {
    getAll: () => {
        return Services.getApi(Config.getAuthBasePath() + '/users');
    },
    reset_password: (id, data) => {
        return Services.putApi(Config.getAuthBasePath() + '/users/' + id + '/password', { password: data });
    },
    delete_password: (email) => {
        return Services.deleteApi(Config.getAuthBasePath() + '/users/' + email + '/password');
    },
    unlock: id => {
        return Services.deleteApi(Config.getAuthBasePath() + '/users/' + id + '/locks');
    }
};

export default UsersModel;
