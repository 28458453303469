import {Breadcrumb, Col, Divider, Row, Spin, Table, Tag} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { checkTranslation } from '../helpers/checkTranslation';
import SynergyAuditsModel from "../data/SynergyAuditsModel";
import moment from "moment";
const SynergyAudit = () => {
    const { serialNum, itemId } = useParams()
    const navigate = useNavigate();

    const [audit, setAudit] = useState()

    useEffect(() => {
        SynergyAuditsModel.getBySerialNumAndItemId(serialNum, itemId)
            .then(res => {
                if (res) {
                    setAudit(res)
                }
            })
            .catch(err => console.log({err}))
    }, [])

    const statusHistoryColumns = [
        {
            title: 'Timestamp',
            dataIndex: 'timestamp',
            key: 'timestamp',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                return <Tag color={SynergyAuditsModel.getStatusTagByNumber(record.status)}>{SynergyAuditsModel.getStatusByNumber(record.status)}</Tag>
            }
        },
    ];

    return !audit
        ? <Spin style={{marginTop: 40}}/>
        : <div style={{padding: 24}}>
            {<Breadcrumb>
                <Breadcrumb.Item>
                    <span className='pointer' onClick={() => {
                        navigate(-1)
                    }}>{checkTranslation('synergyAudits')}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span>Audit Record</span>
                </Breadcrumb.Item>
            </Breadcrumb>}
            <br/>
            <h3 style={{ textAlign: 'start', fontWeight: 700 }}>Audit: #{audit.id} / {audit.itemId}</h3>
            <h3 style={{ textAlign: 'start', fontWeight: 700 }}>Serial Number: {audit.id}</h3>
            <h3 style={{ textAlign: 'start', fontWeight: 700 }}>Item: {audit.itemName}</h3>
            <h3 style={{ textAlign: 'start', fontWeight: 700 }}>ItemId: {audit.itemId}</h3>
            <h3 style={{ textAlign: 'start', fontWeight: 700 }}>Provider: {audit.providerName}</h3>
            <h3 style={{ textAlign: 'start', fontWeight: 700 }}>Property: {audit.propertyName}</h3>
            <h3 style={{ textAlign: 'start', fontWeight: 700 }}>Room: {audit.room}</h3>
            <h3 style={{ textAlign: 'start', fontWeight: 700 }}>Current Status: {  <Tag color={SynergyAuditsModel.getStatusTagByNumber(audit.status)}>{SynergyAuditsModel.getStatusByNumber(audit.status)}</Tag>}</h3>
            <h3 style={{ textAlign: 'start', fontWeight: 700 }}>Work Request Id: {audit.workRequestId || "-"}</h3>
            <h3 style={{ textAlign: 'start', fontWeight: 700 }}>Number of Retries to Synergy API: {audit?.meta?.retries || 0}</h3>
            <Divider><h3>Work Request Status History</h3></Divider>
            <Table
                dataSource={audit?.statusHistory?.sort((a,b) => (b.date - a.date)).map(sh => { return { timestamp: moment(sh.date).format("YYYY-MM-DD HH:ss"), status: sh.status }}) }
                columns={statusHistoryColumns}
                pagination={false}
            />
        </div>

}

export default SynergyAudit;


