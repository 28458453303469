import { Breadcrumb, Col, Row, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { calculateItemPrice } from '../helpers/CalculateModifiers';
import { checkTranslation } from '../helpers/checkTranslation';
import OrdersModel from '../data/OrdersModel';
import ProvidersModel from '../data/ProvidersModel';
import { findTime } from '../helpers/findTime';
import { useSelector } from 'react-redux';
import PropertyModel from "../data/PropertyModel";

const Order = () => {
    const { tab } = useSelector(state => state);
    const { id } = useParams()
    const location = useLocation();
    const navigate = useNavigate();

    const [order, setOrder] = useState()
    const status = location.state?.status;

    useEffect(() => {
        OrdersModel.getById(id)
            .then(res => {
                if (res) {
                    Promise.all([ProvidersModel.getById(res.providerId), res.hotelId ? PropertyModel.getById(res.hotelId) : Promise.resolve()])
                        .then(responses => {
                            setOrder({ ...res, provider: responses[0]?.name, hotel: responses[1]?.name })
                        })

                }
            })
            .catch(err => console.log({ err }))

    }, [])

    const checkIfHistory = () => order.status >= 0 && order.status <= 10;

    return !order
        ? <Spin style={{ marginTop: 40 }} />
        : <div style={{ padding: 24 }}>
            {status !== undefined && <Breadcrumb>
                <Breadcrumb.Item>
                    <span className='pointer' onClick={() => {
                        navigate(-1)
                    }}>{status === 'live' ? 'Live Orders' : checkTranslation('archive')}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span>{checkTranslation('order')}</span>
                </Breadcrumb.Item>
            </Breadcrumb>}

            <h1 style={{ textAlign: 'start', fontWeight: 700 }}>#{order.id} - {tab === 1 && order.meta.ticket && `Ticket: ${order.meta.ticket}`}</h1>
            <br />
            <br />
            <Row style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }} >
                <Col span={8}>

                    <h1 style={{ textAlign: 'start' }}>{checkTranslation('orderSummary')}</h1>
                    <Row>
                        <Col span={11} style={{ textAlign: 'start' }}>
                            {checkIfHistory() && <span
                                className='bold'
                                style={{ color: '#1793C2' }}
                            >
                                {order.status === 0
                                    ? 'Incoming'
                                    : order.status === 1
                                        ? 'In Preparation'
                                        : order.status === 2
                                            ? 'Ready'
                                            : order.status === 3
                                                ? 'Picked Up'
                                                : order.status === 4
                                                    ? 'No Show'
                                                    : 'Cancel'
                                }
                                <br />
                                <br />
                            </span>
                            }
                            <div className='flex'>
                                <span className='bold'>{checkTranslation('requestReceived')}: </span>
                                <span style={{ paddingLeft: 6 }}>{moment(order.created).format('HH:mm')}</span>
                            </div>
                            {checkIfHistory() && order.status >= 2 && <div className='flex'>
                                <span className='bold'>{checkTranslation('totalPrepTime')}</span>
                                <span style={{ paddingLeft: 6 }}>
                                    {
                                        findTime(moment(order.created), moment(order.updated))

                                    }</span>
                            </div>}
                            {checkIfHistory() && order.status > 2 && <div className='flex'>
                                <span className='bold'>{checkTranslation('pickUpTime')}</span>
                                <span style={{ paddingLeft: 6 }}>{order.statusHistory.find(x => x.status === 2)?.date ? moment(new Date(order.statusHistory.find(x => x.status === 2)?.date).toString()).format('HH:mm') : '-'}</span>
                                <br />
                                <br />
                                <br />
                            </div>}
                            <div className='flex flex-column align-start'>
                                <span className='bold'>{checkTranslation('PROVIDER')}</span>
                                <span>{order.provider}</span>
                            </div>
                            <br />
                            <br />
                            <div className='flex flex-column align-start'>
                                <span className='bold'>{checkTranslation('total')}</span>
                                <span>{'$' + order.paymentAnalysis.total}</span>
                            </div>
                            <br />
                            <br />
                            {/* {order.paymentAnalysis.discount !== 0 && <div className='flex flex-column align-start'>
                            <span className='bold'>{checkTranslation('discount')}</span>
                            <span>-{'$' + Math.abs(order.paymentAnalysis.discount)}</span>
                        </div>} */}
                        </Col>
                        <Col span={11} style={{ textAlign: 'start', marginLeft: 'auto' }}>
                            <span className='bold'>{`${order.items?.length} ITEMS | ${order.personsOrdering} PERSONS`}</span>
                            <br />
                            <br />
                            <div className='flex flex-column align-start'>
                                <span className='bold'>{checkTranslation('roomNumber')}</span>
                                <span>{order.room}</span>
                            </div>
                            <br />
                            <br />
                            <div className='flex flex-column align-start'>
                                <span className='bold'>{checkTranslation('guestName')}</span>
                                <span>{`${order.firstName} ${order.lastName}`}</span>
                            </div>
                            <br />
                            <br />
                            <div className='flex flex-column align-start'>
                                <span className='bold'>{checkTranslation('guestEmail')}</span>
                                <span>{order.email ? order.email : '-'}</span>
                            </div>
                            <br />
                            <br />
                            <div className='flex flex-column align-start'>
                                <span className='bold'>{checkTranslation('guestPhone')}</span>
                                <span>{order.mobile ? order.mobile : '-'}</span>
                            </div>
                            <br />
                            <br />
                            <div className='flex flex-column align-start'>
                                <span className='bold'>{checkTranslation('hotel')}</span>
                                <span>{order.hotel || order.provider }</span>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={1} />

                <Col span={8}>
                    <h1 style={{ textAlign: 'start' }}>{checkTranslation('orderDetails')}</h1>
                    {order.items?.map(item =>
                        <div className='flex flex-column justify-start align-start' style={{ paddingBottom: 30, marginBottom: 30, borderBottom: '1px solid #ededed' }}>
                            <span className='bold' style={{ fontSize: 20 }}>{`${item.name} x${item.quantity}`} </span>
                            <span style={{ textAlign: 'start' }}>{item.modifiers.map(x => x.rawItems.map(o => `${o.name} ${o?.price ? '$' + o?.price : ''}`)).join(', ')}</span>
                            <span className='bold'>
                                {
                                    item.modifiers?.length > 0
                                        ? `${checkTranslation('itemPrice')} ${checkTranslation('currency')}` + item.quantity * ((Number(item.price) + Number(calculateItemPrice(item)))).toFixed(2)
                                        : `${checkTranslation('itemPrice')} ${checkTranslation('currency')}` + item.quantity * Number(item.price).toFixed(2)
                                }
                            </span>
                            {item?.allergy && <br />}
                            {item?.allergy && <span style={{ backgroundColor: '#FC7226', padding: '8px 16px', borderRadius: 25, color: '#fff' }}>{checkTranslation('allergy')}</span>}
                            {item?.allergy && <span>{item?.allergy}</span>}
                            {/* {item?.dressing && <br />}
                        {item?.dressing && <span className='bold'>{checkTranslation('dressing')}</span>}
                        {item?.dressing && <span>{'Caesar dressing'}</span>} */}
                            <br />
                            <span className='bold'>{checkTranslation('notes')}</span>
                            <span style={{ textAlign: 'left' }}>{item.notes}</span>
                        </div>
                    )}
                    <div className='flex' style={{"flex-direction": "column", "align-items": "flex-start",}}>
                        <span className='bold'>{checkTranslation('orderNotes')}</span>
                        <span style={{textAlign: "justify"}}>{order.notes || "-"}</span>
                    </div>
                    <hr />
                    <br />
                    <div className='flex'>
                        <span className='bold'>{checkTranslation('subtotal')}</span>
                        <span style={{ marginLeft: 'auto' }}>{'$' + order.paymentAnalysis.subTotal}</span>
                    </div>
                </Col>
            </Row>
        </div >
}

export default Order