import { FileDoneOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Input, message, Modal, Row, Table, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import UsersModel from '../data/UsersModel';
import { checkTranslation } from '../helpers/checkTranslation';
import { usersData, usersColumnsHelpDesk, usersColumnsProvider } from '../helpers/TableUsers'

const Users = () => {

    const [users, setUsers] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [searchedColumn, setSearchedColumn] = useState(null);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [passwordVisible, setPasswordVisible] = React.useState(false);

    const [open, setOpen] = useState(false)
    const [pass, setPass] = useState(null)
    const [repeat, setRepeat] = useState(null)
    const [id, setID] = useState(null)

    const searchInput = useRef(null);
    const { dineIn } = useSelector(state => state);


    useEffect(() => {
        UsersModel.getAll()
            .then(res => setUsers(res.map(x => ({
                id: x.id,
                username: `${x.firstName} ${x.lastName}`,
                region: 'Chicago',
                email: x.email,
                meta: x.meta
            }))))
            .catch(err => message.error(111 + err.message))
    }, [])

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleResetAll = (clearFilters) => {
        setFilteredInfo({});
        setSortedInfo({});
    };
    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const changePass = () => {
        if (pass !== repeat) {
            message.error('These two passwords are not identical! Please type the same password in both fields')
        } else {
            UsersModel.reset_password(id, pass)
                .then(res => {
                    setOpen(false)
                    message.success('You have changed the password successfully!')
                })
                .catch(err => message.error(err.message))
        }

    };


    return <div style={{ padding: 24 }}>
        <h1 style={{ textAlign: 'start', marginBottom: 100 }}>{checkTranslation('users')}</h1>
        <Table
            bordered
            dataSource={users}
            columns={usersColumnsProvider(handleReset, handleSearch, searchInput, filteredInfo, setOpen, setID, dineIn,)}
            onChange={handleChange}
            pagination={false}
        />
        <Modal
            open={open}
            onCancel={() => {
                setOpen(false)
            }}
            onOk={changePass}>
            <div>
                <span>{checkTranslation('newPass')}</span>
                <Input.Password
                    visibilityToggle={{
                        visible: passwordVisible,
                        onVisibleChange: setPasswordVisible,
                    }}
                    onChange={({ target: { value } }) => {
                        setPass(value)
                    }} />
                <br />
                <br />
                <span>{checkTranslation('repeatPass')}</span>
                <Input.Password
                    visibilityToggle={{
                        visible: passwordVisible,
                        onVisibleChange: setPasswordVisible,
                    }}
                    onChange={({ target: { value } }) => {
                        setRepeat(value)
                    }} />
            </div>

        </Modal>

    </div>
}

export default Users