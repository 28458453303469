import React, { useEffect, useState } from 'react';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import auth from '../../components/authorization/auth';
import LocalData from '../../helpers/LocalData';
import Config from '../../config';
import LoginModel from '../../data/LoginModel';
import HCNLoader from '../../helpers/HcnLoader';
import { message } from 'antd';
import { checkTranslation } from '../../helpers/checkTranslation';
import { setTab } from '../../redux/Reducer';
import { Authorize } from '../../helpers/Authorize';
import { getQueryParamValue } from "../../utils/functions";

const LogIn = () => {
    const history = useNavigate();
    const [loginForm, setLoginForm] = useState({ userName: '', password: '' });

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const tokenFromParams = getQueryParamValue(location.search, "token")
        if (tokenFromParams) {
            LocalData.set(
                Config.LOCAL_DATA_KEYS.JWT_ACCESS_TOKEN,
                tokenFromParams
            );
            if (auth.isAuthenticated())
                history('/live-orders');
        }

    }, []);

    useEffect(() => {
        HCNLoader.hide(dispatch);
    }, []);

    // const successAttemp = (response) => {
    //     LocalData.set(Config.LOCAL_DATA_KEYS.JWT_ACCESS_TOKEN, response.access_token);
    //     redirectToWelcomePage();
    // };
    // const roleChecker = response => {
    //     let decoded = jwt_decode(response.access_token);
    //     decoded.role === 'admin' || decoded.role === 'provider' ? successAttemp(response) : message.error('The combination of username / password provided was wrong. Please try again.');
    // };

    const onSubmitForm = (event) => {
        event.preventDefault();
        HCNLoader.show(dispatch);
        LoginModel.login({
            'grant_type': 'password',
            username: loginForm.userName,
            password: loginForm.password,
        })
            .then(response => {
                if (response) {
                    history('/live-orders');
                    LocalData.set(Config.LOCAL_DATA_KEYS.JWT_ACCESS_TOKEN, response.access_token);
                    HCNLoader.hide(dispatch);
                    dispatch(setTab(
                        Authorize.dinein.read(response)
                            ? 0
                            : 1
                    ))
                }
            })
            .catch(err => {
                message.error(err.message);
                err.statusCode === 1304 && message.error(checkTranslation('mustResetPass'))
                err.statusCode === 1304 && history('/forgot-pass')
                HCNLoader.hide(dispatch);
            });

    };

    return (
        <div id="page" className="site site--account-credentials">
            <section className="center-block center-block--alone account-credentials">
                <div className="account-credentials--block">
                    <div className="logo">
                        <img src="./assets/img/hcn-logo.png" alt="site logo" className="site-logo" />
                    </div>
                    <h1 className="big-title">{checkTranslation('signInTxt')}</h1>
                    <form onSubmit={onSubmitForm} className="account-credentials--form">
                        <label htmlFor="email">{checkTranslation('email')}
                            <input
                                type="text"
                                id="email"
                                className="default default--full"
                                name="email"
                                onChange={event =>
                                    setLoginForm({
                                        ...loginForm,
                                        userName: event.target.value,
                                    })
                                }
                                placeholder="" />
                        </label>
                        <label htmlFor="password">{checkTranslation('password')}
                            <input
                                type="password"
                                id="password"
                                className="default default--full"
                                name="email"
                                autoComplete="on"
                                onChange={event =>
                                    setLoginForm({
                                        ...loginForm,
                                        password: event.target.value,
                                    })
                                }
                                placeholder="" />
                        </label>
                        <div className="account-credentials--choices">
                            <label htmlFor="remind-me">
                                <input type="checkbox" id="remind-me" className="default" name="remind-me"
                                    placeholder="" />
                                {checkTranslation('rememberMe')}
                            </label>
                            <NavLink to="/forgot-pass" className="link">{checkTranslation('forgotPass')}</NavLink>
                        </div>
                        <button type="submit" id="sign-in" className="btn btn--secondary btn--full">{checkTranslation('signIn')}</button>
                    </form>
                </div>
            </section>

        </div>
    );
};

export default LogIn;
