import moment from 'moment';
import { checkTranslation } from './checkTranslation';
import SynergyAuditsModel from "../data/SynergyAuditsModel";
import {Button, Input, Space, Tag} from "antd";
import React from "react";
import {SearchOutlined} from "@ant-design/icons";


export const synergyAuditColumns = (
    searchInput,
    sortedInfo,
    filteredInfo,
    dineIn
) => [
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('no')}</span>,
            dataIndex: 'idx',
            key: 'idx',
            fixed: 'left',
            width: 50,
        },

        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('serialNumber')}</span>,
            dataIndex: 'id',
            key: 'id',
            width: 150,
            render: (id, record) => <span style={{ color: '#6B7280' }}>{id}</span>,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <Input
                        ref={searchInput}
                        allowClear
                        placeholder={checkTranslation('searchBySerialNum')}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{
                            marginBottom: 8,
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                confirm()
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            {checkTranslation('search')}
                        </Button>
                    </Space>
                </div>,
            filterIcon: (filtered) =>
                <SearchOutlined
                    style={{
                        color: filtered ? '#1890ff' : undefined,
                        padding: 4
                    }}
                />,
            filteredValue: filteredInfo?.id || null,
        },

        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('hotel')}</span>,
            dataIndex: 'propertyName',
            key: 'propertyName',
            width: 150,
            render: (hotel, record) => <span style={{ color: '#6B7280' }}>{hotel}</span>,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <Input
                        ref={searchInput}
                        allowClear
                        placeholder={checkTranslation('searchByPropertyId')}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{
                            marginBottom: 8,
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                confirm()
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            {checkTranslation('search')}
                        </Button>
                    </Space>
                </div>,
            filterIcon: (filtered) =>
                <SearchOutlined
                    style={{
                        color: filtered ? '#1890ff' : undefined,
                        padding: 4
                    }}
                />,
            filteredValue: filteredInfo?.propertyName || null,
        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('room')}</span>,
            dataIndex: 'room',
            key: 'room',
            width: 150,
            render: (room, record) => <span style={{ color: '#6B7280' }}>{room}</span>,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <Input
                        ref={searchInput}
                        allowClear
                        placeholder={checkTranslation('searchByRoom')}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{
                            marginBottom: 8,
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                confirm()
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            {checkTranslation('search')}
                        </Button>
                    </Space>
                </div>,
            filterIcon: (filtered) =>
                <SearchOutlined
                    style={{
                        color: filtered ? '#1890ff' : undefined,
                        padding: 4
                    }}
                />,
            filteredValue: filteredInfo?.room || null,
        },


        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('item')}</span>,
            dataIndex: 'itemName',
            key: 'itemName',
            width: 150,
            render: (item, record) => <span style={{ color: '#6B7280' }}>{item}</span>,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <Input
                        ref={searchInput}
                        allowClear
                        placeholder={checkTranslation('searchByItemId')}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }}
                        onPressEnter={() => {
                            confirm()
                        }}
                        style={{
                            marginBottom: 8,
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                confirm()
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            {checkTranslation('search')}
                        </Button>
                    </Space>
                </div>,
            filterIcon: (filtered) =>
                <SearchOutlined
                    style={{
                        color: filtered ? '#1890ff' : undefined,
                        padding: 4
                    }}
                />,
            filteredValue: filteredInfo?.itemName || null,
        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('provider')}</span>,
            dataIndex: 'providerName',
            key: 'providerName',
            width: 150,
            render: (providerName, record) => <span style={{ color: '#6B7280' }}>{providerName}</span>,
        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('status')}</span>,
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: (status, record) => <span style={{ color: '#6B7280' }}>{
                <Tag color={SynergyAuditsModel.getStatusTagByNumber(record.status)}>{SynergyAuditsModel.getStatusByNumber(record.status)}</Tag>
            }</span>,
            filters: Object.entries(SynergyAuditsModel.getStatusMap()).map(e => ({value: parseInt(e[0]), text: e[1]})),
            onFilter: (value, record) => record.status === value,

        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('workRequestId')}</span>,
            dataIndex: 'workRequestId',
            key: 'workRequestId',
            width: 100,
            render: (workRequestId, record) => <span style={{ color: '#6B7280' }}>{workRequestId || "-"}</span>
        },
        {
            title: <span style={{ color: '#6B7280' }}>{checkTranslation('created')}</span>,
            dataIndex: 'created',
            key: 'created',
            width: 200,
            fixed: 'left',
            render: (created, record) => {
                return <span style={{ color: '#6B7280' }}> {moment(created).format('YYYY-MM-DD')}</span>
            },
        },

    ];
