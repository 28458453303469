import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoutes from '../components/authorization/private-routes';
import LogIn from '../views/login/LogIn';
import ForgotPass from '../views/login/ForgotPass';
import NewPass from '../views/login/NewPassword';
import LiveOrders from '../views/LiveOrders';
import OrderHistory from '../views/OrderHistory';
import Providers from '../views/Providers';
import Users from '../views/Users';
import Order from '../views/Order';
import SynergyAudit from "../views/SynergyAudit";

const RouterNavigator = () => {

    return (
        <>
            <Routes>
                <Route element={<PrivateRoutes />}>
                    <Route path='/live-orders' element={<LiveOrders />} />
                    <Route path='/order/:id' element={<Order />} />
                    <Route path='/synergy-audit/:serialNum/:itemId' element={<SynergyAudit />} />
                    <Route path='/order-history' element={<OrderHistory />} />
                    <Route path='/providers' element={<Providers />} />
                    <Route path='/users' element={<Users />} />
                </Route>
                {/* login */}
                <Route path='/' element={<LogIn />}></Route>
                <Route path='/forgot-pass' element={<ForgotPass />} />
                <Route path='/new-pass' element={<NewPass />} />
            </Routes>
        </>
    );

};

export default RouterNavigator;
