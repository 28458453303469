import { Input, Row, Space, Button, Tooltip, message } from 'antd'
import { EditOutlined, LockOutlined, SearchOutlined, UnlockOutlined, } from '@ant-design/icons';
import { checkTranslation } from './checkTranslation';
import UsersModel from '../data/UsersModel';

export const usersColumnsProvider = (handleReset, handleSearch, searchInput, filteredInfo, setOpen, setID, dineIn) => [
    {
        title: <span style={{ color: '#6B7280' }}>{checkTranslation('region')}</span>,
        dataIndex: 'region',
        key: 'region',
        fixed: 'left',
        width: 650,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={checkTranslation('searchByRegion')}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, 'region')}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, 'region')}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {checkTranslation('search')}
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            clearFilters && handleSearch(selectedKeys, confirm, 'region')
                        }
                        }
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {checkTranslation('reset')}
                    </Button>
                </Space>
            </div>,
        filterIcon: (filtered) =>
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />,
        filteredValue: filteredInfo.region || null,
        onFilter: (value, record) =>
            record.region?.toString().toLowerCase().includes(value.toLowerCase()),
        render: (region, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>{region}</span>,

    },
    // {
    //     title: <span style={{ color: '#6B7280' }}>{checkTranslation(dineIn ? 'RESTAURANT' : 'PROVIDER')}</span>,
    //     dataIndex: 'provider',
    //     key: 'provider',
    //     fixed: 'left',
    //     width: 450,
    //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
    //         <div
    //             style={{
    //                 padding: 8,
    //             }}
    //         >
    //             <Input
    //                 ref={searchInput}
    //                 placeholder={checkTranslation(dineIn ? 'searchByRest' : 'searchByProvider')}
    //                 value={selectedKeys[0]}
    //                 onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //                 onPressEnter={() => handleSearch(selectedKeys, confirm, 'provider')}
    //                 style={{
    //                     marginBottom: 8,
    //                     display: 'block',
    //                 }}
    //             />
    //             <Space>
    //                 <Button
    //                     type="primary"
    //                     onClick={() => handleSearch(selectedKeys, confirm, 'provider')}
    //                     icon={<SearchOutlined />}
    //                     size="small"
    //                     style={{
    //                         width: 90,
    //                     }}
    //                 >
    //                     {checkTranslation('search')}
    //                 </Button>
    //                 <Button
    //                     onClick={() => {
    //                         clearFilters && handleReset(clearFilters)
    //                         clearFilters && handleSearch(selectedKeys, confirm, 'provider')
    //                     }
    //                     }
    //                     size="small"
    //                     style={{
    //                         width: 90,
    //                     }}
    //                 >
    //                     {checkTranslation('reset')}
    //                 </Button>
    //             </Space>
    //         </div>,
    //     filterIcon: (filtered) =>
    //         <SearchOutlined
    //             style={{
    //                 color: filtered ? '#1890ff' : undefined,
    //             }}
    //         />,
    //     filteredValue: filteredInfo.provider || null,
    //     onFilter: (value, record) =>
    //         record.provider?.toString().toLowerCase().includes(value.toLowerCase()),
    //     render: (provider, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>{provider}</span>,
    // },
    {
        title: <span style={{ color: '#6B7280' }}>{checkTranslation('username')}</span>,
        dataIndex: 'username',
        key: 'username',
        fixed: 'left',
        width: 650,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={checkTranslation('searchByUsername')}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, 'username')}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, 'username')}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {checkTranslation('search')}
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            clearFilters && handleSearch(selectedKeys, confirm, 'username')
                        }
                        }
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {checkTranslation('reset')}
                    </Button>
                </Space>
            </div>,
        filterIcon: (filtered) =>
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />,
        filteredValue: filteredInfo.username || null,
        onFilter: (value, record) =>
            record.username?.toString().toLowerCase().includes(value.toLowerCase()),
        render: (username, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>{username}</span>,
    },
    {
        title: <span style={{ color: '#6B7280' }}>{checkTranslation('userEmail')}</span>,
        dataIndex: 'email',
        key: 'email',
        fixed: 'left',
        width: 650,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={checkTranslation('searchByEmail')}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, 'email')}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, 'email')}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {checkTranslation('search')}
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            clearFilters && handleSearch(selectedKeys, confirm, 'email')
                        }
                        }
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {checkTranslation('reset')}
                    </Button>
                </Space>
            </div>,
        filterIcon: (filtered) =>
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />,
        filteredValue: filteredInfo.email || null,
        onFilter: (value, record) =>
            record.email?.toString().toLowerCase().includes(value.toLowerCase()),
        render: (email, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>{email}</span>,
    },
    {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: 150,
        render: (a, record) => <Row>

            {record?.meta?.lockedUntil && <LockOutlined onClick={() => {
                UsersModel.unlock(record.id)
                    .then(res => {
                        window.location.reload()
                    })
                    .catch(err => message.error(err.message))
            }} />}
            <EditOutlined style={{ cursor: 'pointer' }} onClick={() => {
                setID(record.id)
                setOpen(true)
            }} />
        </Row>
    }
]

export const usersColumnsHelpDesk = (handleReset, handleSearch, searchInput, filteredInfo, setOpen) => [
    {
        title: <span style={{ color: '#6B7280' }}>{checkTranslation('region')}</span>,
        dataIndex: 'region',
        key: 'region',
        fixed: 'left',
        width: 450,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={checkTranslation('searchByRegion')}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, 'region')}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, 'region')}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {checkTranslation('search')}
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            clearFilters && handleSearch(selectedKeys, confirm, 'region')
                        }
                        }
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {checkTranslation('reset')}
                    </Button>
                </Space>
            </div>,
        filterIcon: (filtered) =>
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />,
        filteredValue: filteredInfo.region || null,
        onFilter: (value, record) =>
            record.region?.toString().toLowerCase().includes(value.toLowerCase()),
        render: (region, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>{region}</span>,

    },
    {
        title: <span style={{ color: '#6B7280' }}>{checkTranslation('helpDesk')}</span>,
        dataIndex: 'helpdesk',
        key: 'helpdesk',
        fixed: 'left',
        width: 450,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={checkTranslation('searchByHelpDesk')}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, 'helpdesk')}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, 'helpdesk')}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {checkTranslation('search')}
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            clearFilters && handleSearch(selectedKeys, confirm, 'helpdesk')
                        }
                        }
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {checkTranslation('reset')}
                    </Button>
                </Space>
            </div>,
        filterIcon: (filtered) =>
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />,
        filteredValue: filteredInfo.helpdesk || null,
        onFilter: (value, record) =>
            record.helpdesk?.toString().toLowerCase().includes(value.toLowerCase()),
        render: (helpdesk, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>{helpdesk}</span>,
    },
    {
        title: <span style={{ color: '#6B7280' }}>{checkTranslation('username')}</span>,
        dataIndex: 'username',
        key: 'username',
        fixed: 'left',
        width: 450,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={checkTranslation('searchByUsername')}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, 'username')}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, 'username')}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {checkTranslation('search')}
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            clearFilters && handleSearch(selectedKeys, confirm, 'username')
                        }
                        }
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {checkTranslation('reset')}
                    </Button>
                </Space>
            </div>,
        filterIcon: (filtered) =>
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />,
        filteredValue: filteredInfo.username || null,
        onFilter: (value, record) =>
            record.username?.toString().toLowerCase().includes(value.toLowerCase()),
        render: (username, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>{username}</span>,
    },
    {
        title: <span style={{ color: '#6B7280' }}>{checkTranslation('userEmail')}</span>,
        dataIndex: 'email',
        key: 'email',
        fixed: 'left',
        width: 450,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={checkTranslation('searchByEmail')}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, 'email')}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, 'email')}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {checkTranslation('search')}
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            clearFilters && handleSearch(selectedKeys, confirm, 'email')
                        }
                        }
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {checkTranslation('reset')}
                    </Button>
                </Space>
            </div>,
        filterIcon: (filtered) =>
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />,
        filteredValue: filteredInfo.email || null,
        onFilter: (value, record) =>
            record.email?.toString().toLowerCase().includes(value.toLowerCase()),
        render: (email, record) => <span style={{ marginLeft: 2, color: '#6B7280' }}>{email}</span>,
    },
    {

        title: '',
        dataIndex: 'action',
        key: 'action',
        width: 150,
        render: () => <EditOutlined style={{ cursor: 'pointer' }} onClick={() => {
            setOpen(true)
        }} />
    }
]