import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyCFFSUBV8UGTGuNMlnlpWF_f6b3FPdzEI0",
    authDomain: "hcn-helpdesk.firebaseapp.com",
    projectId: "hcn-helpdesk",
    storageBucket: "hcn-helpdesk.appspot.com",
    messagingSenderId: "546821443325",
    appId: "1:546821443325:web:f7cf4bf2ad05b76efaf894",
    measurementId: "G-8CKNR1EYBC"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getOrRegisterServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        return window.navigator.serviceWorker
            .getRegistration('/firebase-push-notification-scope')
            .then((serviceWorker) => {
                if (serviceWorker) return serviceWorker;
                return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
                    scope: '/firebase-push-notification-scope',
                });
            });
    }
    throw new Error('The browser doesn`t support service worker.');
};

export const onForegroundMessage = () =>
    new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));

export default firebaseApp