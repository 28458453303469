import React from 'react';
import Auth from './auth';
import {Navigate, Outlet} from 'react-router-dom';

const PrivateRoutes = () => {
    const isAuth = Auth.isAuthenticated();

    return isAuth ? <Outlet /> : <Navigate to='/' />;
};

export default PrivateRoutes;
