import Services from './Services';
import Config from '../config';

const MyProfile = {
    get: () => {
        return Services.getApi(Config.getAuthBasePath() + '/my-profile');
    },
    getPermissions: (id) => {
        return Services.getApi(Config.getAuthBasePath() + '/my-permissions');
    },
};

export default MyProfile;