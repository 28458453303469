import { LeftOutlined, RightCircleFilled, RightCircleTwoTone, RightOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import OrdersModel from '../data/OrdersModel';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const DatagridCard = ({ isServiced, incoming, setVisible, item, setOrderID, setOrderStatus, setRefresh, refresh, tab }) => {

    const { userRedux } = useSelector(state => state);

    return <Card
        hoverable
        style={{
            display: 'block',
            cursor: 'default',
            marginTop: 24,
            height: tab === 2 ? 300 : 460
        }}

    >
        <Row style={{ justifyContent: 'space-between', alignItems: 'flex-start', textAlign: 'start' }}>
            <Col span={16}>
                <strong>{moment(item.created).format('HH:mm')}</strong>
                <br />
                <br />
                {tab !== 2 && <strong>{moment().diff(moment(item.created), 'minutes')} min ago</strong>}
                {tab !== 2 && <br />}
                {tab !== 2 && <br />}
                <strong>Room: {item.room}</strong>
                <br />
                {tab !== 2 && <span>Name: {`${item.firstName} ${item.lastName}`}</span>}
                {tab !== 2 && <br />}
                {tab !== 2 && <span>Mobile: {`${item.mobile || ''}`}</span>}
                {tab !== 2 && <br />}
                {tab !== 2 && <span>Email: {`${item.email || ''}`}</span>}
                {tab !== 2 && <br />}


                <br />
                <br />
                <span style={{ color: '#000', fontWeight: 700 }}>DETAILS</span>
                <br />
                {tab === 1 && item.meta.ticket && <span>Ticket: {item.meta.ticket}</span>}
                <br />
                <br />

            </Col>
            <Col span={8}>
                {item.status > 0
                    ? <div style={{ width: '100%' }}>
                        <span style={{ fontSize: 12 }} ><strong>DISPATCHER:</strong> {`${userRedux.firstName} ${userRedux.lastName}`}</span>
                        <br />
                        <span style={{ fontSize: 12 }} ><strong>ASSIGNEE:</strong> {`${item.assignee.firstName} ${item.assignee.lastName}`}</span>
                    </div>
                    : <div style={{ height: 117 }} />
                }
            </Col>
        </Row>

        <Row style={{ justifyContent: 'space-between', alignItems: 'flex-start', textAlign: 'start' }}>
            <Col span={22}>

                {incoming && <Button
                    style={{ width: 45, cursor: 'pointer' }}
                    type="primary"
                    shape='round'
                    icon={<LeftOutlined />}
                    onClick={() => {
                        OrdersModel.updateStatus(item.id, item.status - 1)
                            .then(res => {
                                res && setRefresh(!refresh)
                            })
                            .catch(err => console.log({ err }))
                    }}
                />}
            </Col>
            <Col span={2}>

                {!isServiced && <Button
                    style={{ width: 45, cursor: 'pointer' }}
                    type="primary"
                    shape='round'
                    icon={<RightOutlined />}
                    onClick={() => {
                        if (item.status < 2) {
                            setVisible(true)
                            setOrderID(item.id)
                            setOrderStatus(item.status)
                        } else {
                            OrdersModel.updateStatus(item.id, item.status + 1)
                                .then(res => {
                                    res && setRefresh(!refresh)
                                })
                                .catch(err => console.log({ err }))
                        }
                    }}
                />}
            </Col>
        </Row>
        <Row style={{ justifyContent: 'space-between', alignItems: 'flex-end', textAlign: 'start' }}>
            <Col>
                {!isServiced
                    ? <div style={{ display: 'flex', marginTop: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', width: '50%' }}>
                        <span style={{ fontSize: 11, marginLeft: 8 }}>REQUEST TIME</span>
                        {tab === 2
                            ? <span style={{
                                borderRadius: 25,
                                paddingTop: 4,
                                textAlign: 'center',
                                width: 120,
                                height: 30,
                                backgroundColor: '#FC7226',
                                color: '#fff',
                            }}>
                                {moment(item.meta.from).format('hh a')} - {moment(item.meta.from).format('hh a')}
                            </span>
                            : <span
                                style={{
                                    borderRadius: 25,
                                    // padding: 8,
                                    textAlign: 'center',
                                    width: 100,
                                    height: 30,
                                    paddingTop: 4,
                                    backgroundColor: '#FC7226',
                                    color: '#fff',
                                }}
                            >
                                {moment(item.meta.time).format('HH:mm')}
                            </span>}
                    </div>
                    : <div style={{ display: 'flex', marginTop: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', width: '50%' }}>

                        <span style={{
                            borderRadius: 25,
                            paddingTop: 4,
                            width: 100,
                            marginTop: 20,
                            textAlign: 'center',
                            backgroundColor: '#589A1D',
                            color: '#fff',
                            cursor: 'pointer'
                        }}
                            shape="round"
                            onClick={() => {
                                OrdersModel.updateStatus(item.id, item.status + 1)
                                    .then(res => {
                                        res && setRefresh(!refresh)
                                    })
                                    .catch(err => console.log({ err }))
                            }}>
                            Archive
                        </span>
                    </div>
                }
            </Col>
            <Col>
                <Link to={'/order/' + item.id} state={{ record: item, status: 'live' }} style={{ color: '#000', fontWeight: 700 }}>Order Details <RightOutlined /> </Link>
            </Col>
        </Row>
    </Card>
}

export default DatagridCard