import Services from './Services';
import Config from '../config';

const PropertyModel = {
    getById: (id) => {
        return Services.getApi(Config.getAuthBasePath() + '/properties/' + id);
    },
    edit: (id, data) => {
        return Services.putApi(Config.getAuthBasePath() + '/properties/' + id, data);
    },
};

export default PropertyModel;