import { hasAccess } from '@dotsyntax-pliroforiki/hcn-core'

export const Authorize = {
    dinein: {
        read: permissions => permissions && hasAccess(permissions, 'dinein', 'R'),
        readAll: permissions => permissions && hasAccess(permissions, 'dinein', 'RA'),
        edit: permissions => permissions && hasAccess(permissions, 'dinein', 'E'),
    },
    valet: {
        read: permissions => permissions && hasAccess(permissions, 'valet', 'R'),
        readAll: permissions => permissions && hasAccess(permissions, 'valet', 'RA'),
        edit: permissions => permissions && hasAccess(permissions, 'valet', 'E'),
    },
    housekeeping: {
        read: permissions => permissions && hasAccess(permissions, 'housekeeping', 'R'),
        readAll: permissions => permissions && hasAccess(permissions, 'housekeeping', 'RA'),
        edit: permissions => permissions && hasAccess(permissions, 'housekeeping', 'E'),
    },
    amenities: {
        read: permissions => permissions && hasAccess(permissions, 'amenities', 'R'),
        readAll: permissions => permissions && hasAccess(permissions, 'amenities', 'RA'),
        edit: permissions => permissions && hasAccess(permissions, 'amenities', 'E'),
    },
    users: {
        read: permissions => permissions && hasAccess(permissions, 'users', 'R'),
        readAll: permissions => permissions && hasAccess(permissions, 'users', 'RA'),
        edit: permissions => permissions && hasAccess(permissions, 'users', 'E'),
    },
    providers: {
        read: permissions => permissions && hasAccess(permissions, 'providers', 'R'),
        readAll: permissions => permissions && hasAccess(permissions, 'providers', 'RA'),
        edit: permissions => permissions && hasAccess(permissions, 'providers', 'E'),
    },
    tray_pickup: {
        read: permissions => permissions && hasAccess(permissions, 'tray_pickup', 'R'),
        readAll: permissions => permissions && hasAccess(permissions, 'tray_pickup', 'RA'),
        edit: permissions => permissions && hasAccess(permissions, 'tray_pickup', 'E'),
    },
}