import { setLoading } from '../redux/Reducer';

const HCNLoader = {
    show: dispatch => {
        dispatch(setLoading(true));
    },
    hide: dispatch => {
        dispatch(setLoading(false));
    },
};

export default HCNLoader;
