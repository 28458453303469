import { message } from 'antd';
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import UsersModel from '../../data/UsersModel';
import { checkTranslation } from '../../helpers/checkTranslation';

const ForgotPass = () => {

    const [email, setEmail] = useState(null)
    const nav = useNavigate();

    const resetPass = () => {
        UsersModel.delete_password(encodeURIComponent(email))
            .then(() => {
                nav('/')
            })
            .catch(err => message.error(err.message))
    };


    return (
        <div id="page" className="site site--account-credentials">

            <section className="center-block center-block--alone account-credentials">
                <div className="account-credentials--block">
                    <div className="logo">
                        <img src="./assets/img/hcn-logo.png" alt="site logo" className="site-logo" />
                    </div>
                    <h1 className="big-title">{checkTranslation('forgotPass')}</h1>
                    <p>{checkTranslation('resetLinkInput')}</p>

                    <div className="account-credentials--form">
                        <label htmlFor="email">{checkTranslation('email')}
                            <input type="email" id="email" className="default default--full" name="email"
                                placeholder="" onChange={({ target: { value } }) => setEmail(value)} />
                        </label>
                        <button id="sign-in" className="btn btn--secondary btn--full" onClick={resetPass}>{checkTranslation('sendLink')}</button>
                    </div>

                    <NavLink to="/" className="link top-spacing back">{checkTranslation('backToLogin')}</NavLink>
                </div>
            </section>
        </div>
    );
};

export default ForgotPass;