import Services from './Services';
import Config from '../config';

const LoginModel = {
    login: (criteria) => {
        return Services.postApi(Config.getAuthBasePath() + '/oauth/token', criteria, true);
    },
};

export default LoginModel;
