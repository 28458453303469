import { Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import UsersModel from '../data/UsersModel';

const { Option } = Select;

const AssignTask = ({
    person,
    setPerson,
    setPhoneEmail,
}) => {

    const [users, setUsers] = useState([])

    useEffect(() => {
        UsersModel.getAll()
            .then(res => setUsers(res))
            .catch(err => console.log({ err }))
    }, [])

    return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <span style={{ fontSize: 22 }}>Assign Task</span>
        <span>Please select person and send notification</span>
        <br />
        <br />
        <Select placeholder="Select Person" style={{ width: '50%' }} allowClear onChange={value => setPerson(value)}>
            {users.map(x => <Option value={x.id}>{`${x.firstName} ${x.lastName}`}</Option>)}
        </Select>
    </div>
}

export default AssignTask