import moment from "moment";

export const findTime = (startMoment, endMoment) => {
    const minutesDiff = endMoment.diff(startMoment, 'minutes');
    const hoursDiff = endMoment.diff(startMoment, 'hours');
    const daysDiff = endMoment.diff(startMoment, 'days');
    const weeksDiff = endMoment.diff(startMoment, 'weeks');
    const monthsDiff = endMoment.diff(startMoment, 'months');
    const yearsDiff = endMoment.diff(startMoment, 'years');

    return minutesDiff < 60 ? `${minutesDiff}m` : hoursDiff < 24 ? `${hoursDiff}h` : daysDiff < 7 ? `${daysDiff}d` : weeksDiff < 4 ? `${weeksDiff}w` : monthsDiff < 12 ? `${monthsDiff}months` : `${yearsDiff}y`
}