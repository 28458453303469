import './App.css';
import './antd.less';
import Layout from './layout';
import Loader from './components/loader/loader';
import { useEffect, useState } from 'react';
import { store } from './redux/Store';
import Config from './config';
import Auth from './components/authorization/auth';
import LocalData from './helpers/LocalData';
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { checkTranslation } from './helpers/checkTranslation';
import { getMessaging, getToken, onMessage, } from 'firebase/messaging';
import app, { onForegroundMessage } from './firebase';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { message } from 'antd';
import { setFcmToken } from './redux/Reducer';

function App() {
    const { loadingRedux, typeRedux, userRedux } = useSelector((state) => state);

    const dispatch = useDispatch();

    const messaging = getMessaging(app);
    const activateMessages = async () => {
        const token = await getToken(messaging, {
            vapidKey: 'BJy4w6vV3OzjroT5nsjYy-mh47xMLTwHd97-lmGfTTQAlwwITz2o15CkzvwIOlc0e-BgVFP7kc7Qq1ztvVGQpFE'
        })
            .catch(error => console.log("error generatin token", error))
        if (token) {
            dispatch(setFcmToken(token))
            console.log("token", token)
        }
        if (!token) console.log("no token")
    }

    useEffect(() => {
        const jwt_access_token = LocalData.get(Config.LOCAL_DATA_KEYS.JWT_ACCESS_TOKEN);
        const d = new Date();
        const nowTime = d.getTime();
        const token = jwt_access_token && jwtDecode(jwt_access_token);

        if (token && userRedux) {
            setTimeout(() => {
                message.warning(checkTranslation('signOutWarning'));
            }, new Date(token.exp * 1000 - nowTime - 120000).getTime());
        }

        const unsubscribeRedux = store.subscribe(() => {
            if (typeRedux === Config.REDUCER_TYPES.SET_LOADING) {
                setLoading(loadingRedux);
            }
        });
        activateMessages()
        onForegroundMessage()
            .then((payload) => {
                const { notification: { title, body } } = payload;
                toast(<ToastifyNotification title={title} body={body} />, { autoClose: 10000, onClick: () => window.open(payload.fcmOptions.link, '_blank') });

            })
            .catch(err => console.log('An error occured while retrieving foreground message. ', err));

        return () => {
            unsubscribeRedux();
        };
    }, []);

    const ToastifyNotification = ({ title, body }) => (
        <div className="push-notification" onClick={() => {
            window.open('https://hcn-helpdesk.dotsyntax.gr/live-orders', '_blank');
        }}>
            <h2 className="push-notification-title">{title}</h2>
            <p className="push-notification-text">{body}</p>
        </div>
    );

    return (
        <div className="App">
            <ToastContainer />
            <Loader loading={loadingRedux} />
            <Layout />
        </div>
    );
}

export default App;
