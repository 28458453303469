import { FileDoneOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, message, Modal, Row, Spin, Switch, Table, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AssignTask from '../components/AssignTask';
import DataGrid from '../components/DataGrid';
import Radios from '../components/Radios';
import Statistics from '../components/Statistics';
import OrdersModel from '../data/OrdersModel';
import { checkTranslation } from '../helpers/checkTranslation';
import { liveOrderColumns, liveOrderColumnsHouseKeeping } from '../helpers/TableLiveOrders'
import { Authorize } from '../helpers/Authorize';
import { setAssignee, setFilteredInfo, setPageNumber, setPageSize, setSortedInfo, setTab } from '../redux/Reducer';
import {synergyAuditColumns} from "../helpers/TableSynergyAudits";
import SynergyAuditsModel from "../data/SynergyAuditsModel";
import {useNavigate} from "react-router-dom";


const LiveOrders = () => {
    const {
        dineIn,
        userPermissions,
        tab,
        allMine,
        filteredInfo,
        sortedInfo,
        pageNumber,
        pageSize,
        assignee,
    } = useSelector(state => state);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [openRequests, setOpenRequests] = useState(null);
    const [synergyAuditRecords, setSynergyAuditRecords] = useState(null);
    const [synergyAuditTotalPages, setSynergyAuditTotalPages] = useState(null);
    const [incoming, setIncoming] = useState([])
    const [inPrep, setInPrep] = useState([])
    const [serviced, setServiced] = useState([])
    const [statistics, setStatistics] = useState(null);
    const [totalPages, setTotalPages] = useState(null);
    const [tableVisible, setTableVisible] = useState(true)
    const [visible, setVisible] = useState(false)
    const [person, setPerson] = useState(null)
    const [orderID, setOrderID] = useState(0)
    const [orderStatus, setOrderStatus] = useState(0)
    const [refresh, setRefresh] = useState(false)
    const [reloadAPI, setReloadAPI] = useState(false);

    const searchInput = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            setReloadAPI(!reloadAPI)
        }, 30000);
    }, [openRequests])

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!tableVisible) {
            tab >= 0 && OrdersModel.getAll(
                1,
                100,
                null,
                null,
                null,
                null,
                null,
                [0],
                tab,
                sortedInfo,
                assignee,
            )
                .then(res => {
                    res && res.totalPages && setTotalPages(res.total)
                    setIncoming(res.data)
                })
                .catch(err => console.log({ err }))
            tab >= 0 && OrdersModel.getAll(
                1,
                100,
                null,
                null,
                null,
                null,
                null,
                [1],
                tab,
                sortedInfo,
                assignee,
            )
                .then(res => {
                    res && res.totalPages && setTotalPages(res.total)
                    setInPrep(res.data)
                })
                .catch(err => console.log({ err }))
            tab >= 0 && OrdersModel.getAll(
                1,
                100,
                null,
                null,
                null,
                null,
                null,
                [2],
                tab,
                sortedInfo,
                assignee,
            )
                .then(res => {
                    res && res.totalPages && setTotalPages(res.total)
                    setServiced(res.data)
                })
                .catch(err => console.log({ err }))
        }
    }, [tableVisible, refresh, assignee, reloadAPI])

    useEffect(() => {
        tab === 5 && SynergyAuditsModel.getAll(
            Number(pageNumber),
            Number(pageSize),
            filteredInfo?.propertyName?.[0],
            filteredInfo?.room?.[0],
            filteredInfo?.itemName?.[0],
            filteredInfo?.id?.[0]

        ) .then(res => {
            res && res.totalPages && setSynergyAuditTotalPages(res.total)
            res && res.data?.length > 0 ?
                setSynergyAuditRecords(
                    res.data.map((x, idx) => ({
                        ...x,
                        idx: idx + 1,
                    })))
                : setSynergyAuditRecords([])
        })
            .catch(err => {
                console.log(err.message)
            })
    }, [pageNumber, pageSize, filteredInfo, tab, refresh])

    useEffect(() => {
        tab >= 0 && tab !== 5 && tableVisible && userPermissions && OrdersModel.getAll(
            Number(pageNumber),
            Number(pageSize),
            filteredInfo?.id?.[0],
            filteredInfo?.provider?.[0],
            filteredInfo?.hotel?.[0],
            filteredInfo?.room?.[0],
            filteredInfo?.guestName?.[0],
            filteredInfo?.status,
            tab,
            sortedInfo,
            assignee,
        )
            .then(res => {
                res && res.totalPages && setTotalPages(res.total)
                res && res.data?.length > 0 ?
                    setOpenRequests(
                        res.data.map((x, idx) => ({
                            ...x,
                            idx: idx + 1,
                            provider: x.provider.name,
                            hotel: x.hotel.name,
                            guestName: `${x.firstName} ${x.lastName}`,
                            subTotal: x.paymentAnalysis.subTotal
                        })))
                    : setOpenRequests([])
            })
            .catch(err => {
                console.log(err.message)
            })
        tab >= 0 && tab !== 5 && userPermissions && OrdersModel.get_statistics(tab, assignee)
            .then(res => res && setStatistics(res))
            .catch(err => console.log({ err }))
    }, [pageNumber, pageSize, filteredInfo, sortedInfo, tab, refresh, tableVisible, allMine, reloadAPI])

    const handleChange = (pagination, filters, sorter, extra) => {
        dispatch(setSortedInfo(sorter))
        dispatch(setFilteredInfo(filters))
    };

    const handleSynergyAuditRowClick = (record) => {
        return (
            navigate(`/synergy-audit/${record.id}/${record.itemId}`)
        );
    };

    const items = [
        Authorize.dinein.read(userPermissions) && {
            label: <span style={{ fontSize: 17 }}>
                {checkTranslation('restaurants')}
            </span>,
            key: 0,
            // children: tableVisible? <div>
            children: <div>
                {statistics && statistics.total >= 0
                    ? <Statistics
                        assignee={assignee}
                        setTotalPages={setTotalPages}
                        pageNumber={Number(pageNumber)}
                        pageSize={Number(pageSize)}
                        filteredInfo={filteredInfo}
                        sortedInfo={sortedInfo}
                        setData={setOpenRequests}
                        tab={tab}
                        statistics={statistics}
                    />
                    : <Spin />
                }
                <br />
                <br />
                <br />

                <div style={{ float: 'right' }}>
                    <Button
                        type='primary'
                        onClick={() => {
                            dispatch(setFilteredInfo(null))
                            dispatch(setSortedInfo(null))
                            dispatch(setAssignee(null))
                            dispatch(setPageNumber(1))
                            dispatch(setPageSize(10))
                            setTimeout(() => { window.location.reload() }, 200)
                        }}
                    >
                        {checkTranslation('clearAllFilters')}
                    </Button>
                </div>
                <br />
                <br />
                <Table
                    bordered
                    dataSource={openRequests}
                    columns={
                        liveOrderColumns(
                            searchInput,
                            sortedInfo,
                            filteredInfo,
                            dineIn,
                        )}
                    scroll={{
                        x: 800,
                    }}
                    pagination={{
                        total: totalPages,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        defaultCurrent: pageNumber,
                        defaultPageSize: pageSize,
                        onChange: (num, size) => {
                            dispatch(setPageSize(size))
                            dispatch(setPageNumber(num))
                        },
                    }}
                    onChange={handleChange}
                />
            </div>
            // : <DataGrid table={tableVisible} setTable={setTableVisible} />,
        },
        Authorize.valet.read(userPermissions) && {
            label: <span style={{ fontSize: 17 }}>
                {checkTranslation('valet')}
            </span>,
            key: 1,
            children: tableVisible
                ? <div>
                    {statistics && statistics.total >= 0
                        ? <Statistics
                            assignee={assignee}
                            setTotalPages={setTotalPages}
                            pageNumber={Number(pageNumber)}
                            pageSize={Number(pageSize)}
                            filteredInfo={filteredInfo}
                            sortedInfo={sortedInfo}
                            setData={setOpenRequests}
                            tab={tab}
                            statistics={statistics}
                        />
                        : <Spin />
                    }
                    <br />
                    <br />
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Radios table={tableVisible} setTable={setTableVisible} />
                        <Button
                            type='primary'
                            onClick={() => {
                                dispatch(setFilteredInfo(null))
                                dispatch(setSortedInfo(null))
                                dispatch(setAssignee(null))
                                dispatch(setPageNumber(1))
                                dispatch(setPageSize(10))
                                setTimeout(() => { window.location.reload() }, 200)
                            }}
                        >
                            {checkTranslation('clearAllFilters')}
                        </Button>
                    </div>
                    <br />
                    <br />
                    <Table
                        bordered
                        dataSource={openRequests}
                        columns={
                            liveOrderColumns(
                                searchInput,
                                sortedInfo,
                                filteredInfo,
                                dineIn,
                            )}
                        scroll={{
                            x: 800,
                        }}
                        pagination={{
                            total: totalPages,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            defaultCurrent: pageNumber,
                            defaultPageSize: pageSize,
                            onChange: (num, size) => {
                                dispatch(setPageSize(size))
                                dispatch(setPageNumber(num))
                            },
                        }}
                        onChange={handleChange}
                    />
                </div>
                : <DataGrid
                    setAssignee={setAssignee}
                    incoming={incoming}
                    inPrep={inPrep}
                    serviced={serviced}
                    statistics={statistics}
                    setOrderStatus={setOrderStatus}
                    setOrderID={setOrderID}
                    pageNumber={Number(pageNumber)}
                    pageSize={Number(pageSize)}
                    setPageNumber={setPageNumber}
                    setVisible={setVisible}
                    table={tableVisible}
                    setTable={setTableVisible}
                    tab={tab}
                    setRefresh={setRefresh}
                    refresh={refresh}
                />,
        },
        Authorize.housekeeping.read(userPermissions) && {
            label: <span style={{ fontSize: 17 }}>
                {checkTranslation('housekeeping')}
            </span>,
            key: 2,
            children: tableVisible
                ? <div>
                    {statistics && statistics.total >= 0
                        ? <Statistics
                            assignee={assignee}
                            setTotalPages={setTotalPages}
                            pageNumber={Number(pageNumber)}
                            pageSize={Number(pageSize)}
                            filteredInfo={filteredInfo}
                            sortedInfo={sortedInfo}
                            setData={setOpenRequests}
                            tab={tab}
                            statistics={statistics}
                        />
                        : <Spin />
                    }
                    <br />
                    <br />
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Radios table={tableVisible} setTable={setTableVisible} />
                        <Button
                            type='primary'
                            onClick={() => {
                                dispatch(setFilteredInfo(null))
                                dispatch(setSortedInfo(null))
                                dispatch(setAssignee(null))
                                dispatch(setPageNumber(1))
                                dispatch(setPageSize(10))
                                setTimeout(() => { window.location.reload() }, 200)
                            }}
                        >
                            {checkTranslation('clearAllFilters')}
                        </Button>
                    </div>
                    <br />
                    <br />
                    <Table
                        bordered
                        dataSource={openRequests}
                        columns={
                            liveOrderColumnsHouseKeeping(
                                searchInput,
                                sortedInfo,
                                filteredInfo,
                                dineIn,
                            )}
                        scroll={{
                            x: 800,
                        }}
                        pagination={{
                            total: totalPages,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            defaultCurrent: pageNumber,
                            defaultPageSize: pageSize,
                            onChange: (num, size) => {
                                dispatch(setPageSize(size))
                                dispatch(setPageNumber(num))
                            },
                        }}
                        onChange={handleChange}
                    />
                </div>
                : <DataGrid
                    setAssignee={setAssignee}
                    incoming={incoming}
                    inPrep={inPrep}
                    serviced={serviced}
                    statistics={statistics}
                    setOrderStatus={setOrderStatus}
                    setOrderID={setOrderID}
                    pageNumber={Number(pageNumber)}
                    pageSize={Number(pageSize)}
                    setPageNumber={setPageNumber}
                    setVisible={setVisible}
                    table={tableVisible}
                    setTable={setTableVisible}
                    tab={tab}
                    setRefresh={setRefresh}
                    refresh={refresh}
                />,
        },
        Authorize.amenities.read(userPermissions) && {
            label: <span style={{ fontSize: 17 }}>
                {checkTranslation('amenities')}
            </span>,
            key: 3,
            children: tableVisible
                ? <div>
                    {statistics && statistics.total >= 0
                        ? <Statistics
                            assignee={assignee}
                            setTotalPages={setTotalPages}
                            pageNumber={Number(pageNumber)}
                            pageSize={Number(pageSize)}
                            filteredInfo={filteredInfo}
                            sortedInfo={sortedInfo}
                            setData={setOpenRequests}
                            tab={tab}
                            statistics={statistics}
                        />
                        : <Spin />
                    }
                    <br />
                    <br />
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Radios table={tableVisible} setTable={setTableVisible} />
                        <Button
                            type='primary'
                            onClick={() => {
                                dispatch(setFilteredInfo(null))
                                dispatch(setSortedInfo(null))
                                dispatch(setAssignee(null))
                                dispatch(setPageNumber(1))
                                dispatch(setPageSize(10))
                                setTimeout(() => { window.location.reload() }, 200)
                            }}
                        >
                            {checkTranslation('clearAllFilters')}
                        </Button>
                    </div>
                    <br />
                    <br />
                    <Table
                        bordered
                        dataSource={openRequests}
                        columns={
                            liveOrderColumns(
                                searchInput,
                                sortedInfo,
                                filteredInfo,
                                dineIn,
                            )}
                        scroll={{
                            x: 800,
                        }}
                        pagination={{
                            total: totalPages,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            defaultCurrent: pageNumber,
                            defaultPageSize: pageSize,
                            onChange: (num, size) => {
                                dispatch(setPageSize(size))
                                dispatch(setPageNumber(num))
                            },
                        }}
                        onChange={handleChange}
                    />
                </div>
                : <DataGrid
                    setAssignee={setAssignee}
                    incoming={incoming}
                    inPrep={inPrep}
                    serviced={serviced}
                    statistics={statistics}
                    setOrderStatus={setOrderStatus}
                    setOrderID={setOrderID}
                    pageNumber={Number(pageNumber)}
                    pageSize={Number(pageSize)}
                    setPageNumber={setPageNumber}
                    setVisible={setVisible}
                    table={tableVisible}
                    setTable={setTableVisible}
                    tab={tab}
                    setRefresh={setRefresh}
                    refresh={refresh}
                />,
        },
        Authorize.tray_pickup.read(userPermissions) && {
            label: <span style={{ fontSize: 17 }}>
                {checkTranslation('pickupTray')}
            </span>,
            key: 4,
            children: tableVisible
                ? <div>
                    {statistics && statistics.total >= 0
                        ? <Statistics
                            assignee={assignee}
                            setTotalPages={setTotalPages}
                            pageNumber={Number(pageNumber)}
                            pageSize={Number(pageSize)}
                            filteredInfo={filteredInfo}
                            sortedInfo={sortedInfo}
                            setData={setOpenRequests}
                            tab={tab}
                            statistics={statistics}
                        />
                        : <Spin />
                    }
                    <br />
                    <br />
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Radios table={tableVisible} setTable={setTableVisible} />
                        <Button
                            type='primary'
                            onClick={() => {

                                setTimeout(() => { window.location.reload() }, 200)
                            }}
                        >
                            {checkTranslation('clearAllFilters')}
                        </Button>
                    </div>
                    <br />
                    <br />
                    <Table
                        bordered
                        dataSource={openRequests}
                        columns={
                            liveOrderColumns(
                                searchInput,
                                sortedInfo,
                                filteredInfo,
                                dineIn,
                            )}
                        scroll={{
                            x: 800,
                        }}
                        pagination={{
                            total: totalPages,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            defaultCurrent: pageNumber,
                            defaultPageSize: pageSize,
                            onChange: (num, size) => {
                                dispatch(setPageSize(size))
                                dispatch(setPageNumber(num))
                            },
                        }}
                        onChange={handleChange}
                    />
                </div>
                : <DataGrid
                    setAssignee={setAssignee}
                    incoming={incoming}
                    inPrep={inPrep}
                    serviced={serviced}
                    statistics={statistics}
                    setOrderStatus={setOrderStatus}
                    setOrderID={setOrderID}
                    pageNumber={Number(pageNumber)}
                    pageSize={Number(pageSize)}
                    setPageNumber={setPageNumber}
                    setVisible={setVisible}
                    table={tableVisible}
                    setTable={setTableVisible}
                    tab={tab}
                    setRefresh={setRefresh}
                    refresh={refresh}
                />,
        },
        // synergy audits might need special permissions
        {
            label: <span style={{ fontSize: 17 }}>
                {checkTranslation('synergyAudits')}
            </span>,
            key: 5,
            children:  <div>
                    <br />
                    <br />
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button
                            type='primary'
                            onClick={() => {
                                dispatch(setFilteredInfo(null))
                                dispatch(setSortedInfo(null))
                                dispatch(setAssignee(null))
                                dispatch(setPageNumber(1))
                                dispatch(setPageSize(10))
                                setTimeout(() => { window.location.reload() }, 200)
                            }}
                        >
                            {checkTranslation('clearAllFilters')}
                        </Button>
                    </div>
                    <br />
                    <br />
                    <Table
                        bordered
                        dataSource={synergyAuditRecords}
                        columns={
                            synergyAuditColumns(
                                searchInput,
                                sortedInfo,
                                filteredInfo,
                                dineIn,
                            )}
                        scroll={{
                            x: 800,
                        }}
                        pagination={{
                            total: synergyAuditTotalPages,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            defaultCurrent: pageNumber,
                            defaultPageSize: pageSize,
                            onChange: (num, size) => {
                                dispatch(setPageSize(size))
                                dispatch(setPageNumber(num))
                            },
                        }}
                        onRow={(record) => ({
                            onClick: () => handleSynergyAuditRowClick(record),
                            onMouseEnter: (event) => {
                                event.currentTarget.style.cursor = 'pointer';
                            },
                            onMouseLeave: (event) => {
                                event.currentTarget.style.cursor = 'default';
                            },
                        })}
                        onChange={handleChange}
                    />
                </div>
        }

    ];

    return <div style={{ padding: 24 }} id='top'>
        <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <h1 style={{ textAlign: 'start' }}>
                {checkTranslation('openRequests')}
            </h1>
        </Row>
        <Tabs
            items={items}
            activeKey={tab}
            onChange={value => {
                dispatch(setTab(value))
                dispatch(setFilteredInfo(null))
                dispatch(setSortedInfo(null))
                dispatch(setAssignee(null))
                dispatch(setPageNumber(1))
                dispatch(setPageSize(10))
            }}
        />
        <Modal
            open={visible}
            onCancel={() => { setVisible(false) }}
            onOk={() => {
                person && OrdersModel.updateStatus(orderID, orderStatus + 1, person)
                    .then(res => {
                        setRefresh(!refresh)
                    })
                    .catch(err => console.log({ err }))
                setVisible(false)
            }}
            okText="Send"
            okButtonProps={{ disabled: !person }}
        >
            <AssignTask person={person} setPerson={setPerson} setVisible={setVisible} />
        </Modal>
    </div>
}

export default LiveOrders