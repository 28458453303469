const LocalData = {
    get: (key, defaultValue = '') => {
        let v = localStorage.getItem(key);
        if (v && v !== '' && v !== 'undefined' && v !== null) {
            return v;
        } else {
            return defaultValue;
        }
    },
    set: (key, value) => {
        localStorage.setItem(key, value);
    },
    remove: key => {
        localStorage.removeItem(key);
    },
};

export default LocalData;
