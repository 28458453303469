import Services from './Services';
import Config from '../config';

const OrdersModel = {
    getAll: (page, size, orderID, provider, hotel, room, guestName, statuses, tabSelected, sortedInfo, assignee) => {
        return Services.getApi(Config.getAuthBasePath() +
            `/orders?pageNumber=${page}&pageSize=${size}` +
            `${orderID ? '&id=' + orderID : ''}` +
            `${provider ? '&provider=' + provider : ''}` + `${hotel ? '&hotel=' + hotel : ''}` +
            `${room ? '&room=' + `${room}` : ''}` +
            `${guestName ? '&guestName=' + guestName : ''}` +
            `${assignee ? '&assignee=' + assignee : ''}` +
            `${statuses && statuses?.length === 1 ? '&statuses=' + statuses[0] : ''}` +
            `${Number(tabSelected) >= 0 ? '&type=' + Number(tabSelected) : ''}` +
            `${statuses && statuses?.length === 2 ? '&statuses=' + statuses[0] + '&statuses=' + statuses[1] : ''}` +
            `${statuses?.length === 0 || statuses?.length === 3 || !statuses ? '&statuses=0&statuses=1&statuses=2' : ''}` +
            `${sortedInfo && sortedInfo?.field === 'updated' && sortedInfo?.order === 'ascend' ? '&orderBy=updated,asc' : sortedInfo?.field === 'updated' && sortedInfo?.order === 'descend' ? '&orderBy=updated,desc' : ''}` +
            `${sortedInfo && sortedInfo.field === 'subTotal' && sortedInfo.order === 'ascend' ? '&orderBy=subTotal,asc' : sortedInfo?.field === 'subTotal' && sortedInfo?.order === 'descend' ? '&orderBy=subTotal,desc' : ''}`
        );
    },
    getAllHistory: (page, size, orderID, provider, hotel, room, guestName, statuses, sortedInfo, tabSelected) => {
        return Services.getApi(Config.getAuthBasePath() + `/orders?pageNumber=${page}&pageSize=${size}` +
            `${orderID ? '&id=' + orderID : ''}` +
            `${provider ? '&provider=' + provider : ''}` +
            `${hotel ? '&hotel=' + hotel : ''}` +
            `${room ? '&room=' + `${room}` : ''}` +
            `${guestName ? '&guestName=' + guestName : ''}` +
            `${Number(tabSelected) >= 0 ? '&type=' + Number(tabSelected) : ''}` +
            `${statuses && statuses?.length === 1 ? '&statuses=' + statuses[0] : ''}` +
            `${statuses && statuses?.length === 2 ? '&statuses=' + statuses[0] + '&statuses=' + statuses[1] : ''}` +
            `${statuses?.length === 0 || statuses?.length === 3 || !statuses ? '&statuses=3&statuses=4&statuses=10' : ''}` +
            `${sortedInfo && sortedInfo?.field === 'updated' && sortedInfo?.order === 'ascend' ? '&orderBy=updated,asc' : sortedInfo?.field === 'updated' && sortedInfo?.order === 'descend' ? '&orderBy=updated,desc' : ''}` +
            `${sortedInfo && sortedInfo.field === 'subTotal' && sortedInfo.order === 'ascend' ? '&orderBy=subTotal,asc' : sortedInfo?.field === 'subTotal' && sortedInfo?.order === 'descend' ? '&orderBy=subTotal,desc' : ''}`
        );
    },
    get_statistics: (tabSelected, assignee) => {
        return Services.getApi(Config.getAuthBasePath() + `/orders/statistics` + `${Number(tabSelected) >= 0 ? '?type=' + Number(tabSelected) : ''}` + `${assignee ? '&assignee=' + assignee : ''}`);
    },
    getById: id => {
        return Services.getApi(Config.getBaseURL() + '/orders/' + id)
    },
    updateStatus: (id, status, assignee) => {
        return Services.putApi(Config.getAuthBasePath() + '/orders/' + id + '/status', { status, assignee });
    }
};

export default OrdersModel;
