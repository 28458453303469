import Services from './Services';
import Config from '../config';

const ProvidersModel = {
    getAll: () => {
        return Services.getApi(Config.getBaseURL() + '/providers?orderStats=true');
    },
    getById: (id) => {
        return Services.getApi(Config.getBaseURL() + '/providers/' + id);
    },
    update: (id, data) => {
        return Services.putApi(Config.getBaseURL() + '/providers/' + id, data);
    },
    getByType: (type) => {
        return Services.getApi(Config.getBaseURL() + `/providers?type=${type}`);
    },
};

export default ProvidersModel;
